import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter, IgnoreProperty } from '@cognitoforms/model.js';

// Certain properties are "extensions" but still need to be persisted/deserialized.
const exceptionRegex = /(_QuantityLimit)|(_QuantityUsed)$/i;

/**
 * Prevents entry pseudo-properties from being serialized/deserialized.
 */
export default class EntryExtensionConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		// do not filter properties on entry index types
		if (prop.containingType.fullName === 'Forms.FormEntryIndex'
		|| (prop.containingType.baseType && prop.containingType.baseType.fullName === 'Forms.FormEntryIndex'))
			return false;
		else if (!context || !context.meta.type.model)
			return false;
		// Property name must look like 'SomeProperty_PseudoProperty'
		else
			return /([A-Z][a-z]+_)[A-Z][a-z]+/g.test(prop.name) && !exceptionRegex.test(prop.name);
	}

	serialize(): PropertySerializationResult {
		return IgnoreProperty;
	}

	deserialize() {
		return IgnoreProperty;
	}
}