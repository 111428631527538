import type { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import VueModel from '@cognitoforms/vuemodel';
import { FormsModel } from '../framework/forms-model';
import combineUrls from 'axios/lib/helpers/combineURLs';
import buildURL from 'axios/lib/helpers/buildURL';
import isAbsoluteURL from 'axios/lib/helpers/isAbsoluteURL';
import type { FormSession } from './form-session';
import type { JsonErrorInfo } from './custom-response-error';
import { CustomResponseError } from './custom-response-error';
import ToastMessage from '../components/ToastMessage';
import { getSiteUrl } from 'src/util/site-url';

export interface ServiceRequestOptions extends AxiosRequestConfig {
	endpoint: string;
	data?: any;
	isCognitoJson?: boolean;
	passive?: boolean;
}

export type ServiceRequestError = Error | AxiosError | RequestCancelledError | CustomResponseError;

export type ServiceRequestResult = {
	response?: AxiosResponse;
	error?: ServiceRequestError;
};

export class RequestCancelledError extends Error {
	constructor() {
		super('cancelled');
	}
}

export class CaptchaError extends Error {
	constructor() {
		super('Captcha validation required.');
	}
}

export class InvalidSharedLinkError extends Error {
	notAvailableMessage: string;

	constructor(notAvailableMessage: string) {
		super('Invalid Shared Link');
		this.notAvailableMessage = notAvailableMessage;
	}
}

export class PublicLinksDisabledError extends Error {
	constructor() {
		super('Public Links Disabled');
	}
}

export class StripePaymentIntentError extends Error {
	constructor() {
		super('Stripe PaymentIntent Failure');
	}
}

export class InternalServerError extends Error {
	constructor() {
		super('Internal server error.');
	}
}

export class ForbiddenError extends Error {
	constructor() {
		super('You do not have permission to edit this entry.');
	}
}

function isJsonErrorInfo(data: any): data is JsonErrorInfo {
	return typeof data === 'object' && data.hasOwnProperty('Type') && data.hasOwnProperty('Message') && data.hasOwnProperty('Data');
}

export function isAxiosError(error: ServiceRequestError): error is AxiosError {
	return error instanceof Error && (error as any).isAxiosError;
}

export class BaseService {
	protected readonly apiKey: string;
	protected sessionToken: string;
	protected readonly client: AxiosInstance;

	constructor(apiKey: string, sessionToken?: string, baseUrl?: string) {
		this.apiKey = apiKey;
		this.sessionToken = sessionToken;

		this.client = axios.create({
			baseURL: baseUrl || getSiteUrl() || window.location.origin,
			method: 'GET',
			xsrfCookieName: null	// prevent axios from reading document.cookies (https://github.com/axios/axios/pull/406)
		});

		this.client.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
		this.client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		this.client.interceptors.request.use(BaseService.modelSerialization);
		this.client.interceptors.response.use(this.receiveServerTime.bind(this));
		if (location.href.startsWith(getSiteUrl()))
			this.client.interceptors.response.use(this.receiveSessionToken.bind(this));
	}

	/**
	 * Generates the URI that the service would use to make a request with the provided configuration.
	 * @param config request configuration used to generate a URI.
	 */
	protected getUri(config: AxiosRequestConfig) {
		config = Object.assign({}, this.client.defaults, config);

		if (config.baseURL && !isAbsoluteURL(config.url))
			config.url = combineUrls(config.baseURL, config.url);

		return buildURL(config.url, config.params, config.paramsSerializer).replace(/^\?/, '');
	}

	/**
	 * Use custom serialization on model instances
	 */
	private static modelSerialization(config: AxiosRequestConfig) {
		if (config.data instanceof VueModel.Entity) {
			config.headers['Content-Type'] = 'application/json+cognito; charset=utf-8';
			config.data = config.data.serialize();
		}
		return config;
	}

	private async transformServiceRequestOptions(options: ServiceRequestOptions) {
		const config = options;
		config.url = options.endpoint;
		config.params = config.params || {};
		config.headers = config.headers || {};

		if (this.sessionToken)
			config.headers['X-SessionToken'] = this.sessionToken;

		if (options.isCognitoJson)
			config.headers['Content-Type'] = 'application/json+cognito, charset=utf-8';

		if (options.passive === true)
			config.headers['X-Passive-Request'] = 1;

		return config;
	}

	async serviceRequest(options: ServiceRequestOptions | string): Promise<ServiceRequestResult> {
		const config = await this.transformServiceRequestOptions(typeof options === 'string'
			? { endpoint: options, method: 'get' }
			: options);

		const result: ServiceRequestResult = { response: null, error: null };

		// Handle the response
		try {
			result.response = await this.client.request(config);
		}
		// Handle the error
		catch (err) {
			if (isAxiosError(err) || axios.isCancel(err)) {
				result.error = this.transformErrorFromResponse(err);
			}
			else {
				result.error = err;
			}
		}

		return result;
	}

	private transformErrorFromResponse(err: AxiosError): ServiceRequestError {
		if (axios.isCancel(err))
			return new RequestCancelledError();

		// Check for a failure that returns a response of type object, i.e. 'JsonErrorInfo'
		if (err.response && err.response.data && isJsonErrorInfo(err.response.data))
			return new CustomResponseError(err.response.data, err.response.status, err.response.statusText);

		return err;
	}

	/**
	 * Determine the server time offset relative to the client time during posts
	 */
	private receiveServerTime(res: AxiosResponse<any>) {
		const serverTime = res.headers['x-server-time'];
		if (serverTime) {
			try {
				FormsModel.config.serverTimeOffset = new Date(serverTime).getTime() - new Date().getTime();
			}
			catch (e) { }
		}
		return res;
	}

	private receiveSessionToken(res: AxiosResponse<any>) {
		const sessionToken = res.headers['x-sessiontoken'];
		if (sessionToken) {
			try {
				const event = new CustomEvent('received-session-token');
				event['token'] = sessionToken;
				document.dispatchEvent(event);
			}
			catch (e) { }
		}
		return res;
	}
}

export class ServiceWithSession extends BaseService {
	session: FormSession;

	constructor(session: FormSession) {
		super(session.apiKey, session.token);
		this.session = session;
		this.client.interceptors.response.use(this.receiveAccessToken.bind(this));
		this.client.interceptors.request.use(this.addOrganizationHeader.bind(this));
	}

	protected getValidationHeaders(): object {
		let validationMethod = null;
		let validationToken = null;
		if (this.session && this.session.botValidation && this.session.botValidation.method && this.session.botValidation.token) {
			validationMethod = this.session.botValidation.method;
			validationToken = this.session.botValidation.token;
		}

		return validationMethod && validationToken ? { [validationMethod]: validationToken } : null;
	}

	private receiveAccessToken(res: AxiosResponse<any>) {
		// capture fresh accessToken
		if (res.data && res.data['accessToken'])
			this.session.accessToken = res.data['accessToken'];
		return res;
	}

	private addOrganizationHeader(config: AxiosRequestConfig) {
		if (this.session.organizationId)
			config.headers['X-Organization'] = this.session.organizationId;
		return config;
	}

	parseError(error: ServiceRequestError): Error {
		if (isAxiosError(error) && error.response && error.response.data === 'captcha')
			return new CaptchaError();
		else if (isAxiosError(error) && error.response && error.response.data.error === 'Invalid Shared Link')
			return new InvalidSharedLinkError('Invalid Shared Link');
		else if (isAxiosError(error) && error.response && error.response.data.error === 'Public Links Disabled')
			return new PublicLinksDisabledError();
		else if (isAxiosError(error) && error.response && error.response.data.error === 'Stripe PaymentIntent Failure')
			return new StripePaymentIntentError();
		else if (isAxiosError(error) && error.response && error.response.status === 500) {
			const errorHeading = this.session.getResource('entry-submission-error-heading');
			ToastMessage(this.session.formId, {
				type: 'error',
				message: errorHeading || 'Internal server error'
			});
			return new InternalServerError();
		}
		else
			return this.parseSvcError(error);
	}

	parseSvcError(error: ServiceRequestError): Error {
		if (error instanceof CustomResponseError) {
			if (error.type === 'Forbidden')
				return new ForbiddenError();
			else if (error.data.error === 'Invalid Shared Link')
				return new InvalidSharedLinkError(error.data.message);
			else if (error.data.error === 'Public Links Disabled')
				return new PublicLinksDisabledError();
			else if (error.data.error === 'Stripe PaymentIntent Failure')
				return new StripePaymentIntentError();
		}
		return error;
	}
}
