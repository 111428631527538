import type { EntityOfType } from '@cognitoforms/model.js';
import type { FormEntryIndex } from '@cognitoforms/types/server-types/forms/model/form-entry-index';
import type { EntryKey } from './entry-view-types';

export function getViewId(index: EntityOfType<FormEntryIndex> | FormEntryIndex) {
	return index.Id.split('|')[0];
}

export function getTimestamp(index: EntityOfType<FormEntryIndex> | FormEntryIndex) {
	return index.Id.split('|')[1];
}

export function getEntryId(index: EntityOfType<FormEntryIndex> | FormEntryIndex) {
	return index.Id.split('|')[2];
}

export function keyFromIndex({ Id, EntryETag, OrderETag, OrderId }: EntityOfType<FormEntryIndex> | FormEntryIndex): EntryKey {
	let EntryId = Id.split('|')[2];
	let Scope = '';
	const dot = EntryId.indexOf('.');
	if (dot > 0) {
		Scope = EntryId.substr(dot + 1);
		EntryId = EntryId.substr(0, dot);
	}
	return {
		EntryId,
		Scope,
		EntryETag,
		OrderETag,
		OrderId
	};
}
