import type { EntityOfType } from '@cognitoforms/model.js';
import { hasValue } from '../../../util/helpers';
import type { Name } from '@cognitoforms/types/server-types/model/name';

export type NameMethods = {
	validateRequiredName(this: EntityOfType<Name> & NameMethods): string;
};

export const NameExtensions: NameMethods = {
	validateRequiredName: function(this: EntityOfType<Name>) {
		// Store the model to use for localization when building the error message
		const model = this.meta.type.model;

		let error = '';

		const firstIsMissing = !hasValue(this.First);
		const lastIsMissing = !hasValue(this.Last);
		if (firstIsMissing && lastIsMissing) {
			error = model.getResource('required-name-first-and-last');
		}
		else if (firstIsMissing) {
			error = model.getResource('required-name-first');
		}
		else if (lastIsMissing) {
			error = model.getResource('required-name-last');
		}

		return error;
	}
};
