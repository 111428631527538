import type { Entity, Property } from '@cognitoforms/model.js';
import { IgnoreProperty, PropertyConverter } from '@cognitoforms/model.js';

export default class IgnoreSignaturePropertiesConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.containingType.fullName === 'Signature' && context.meta.isNew;
	}

	/**
	 * Prevents deserializing Signature sub-properties (Svg/Png/etc) for new instances.
	 */
	deserialize(context: Entity, value: any, prop: Property) {
		return IgnoreProperty;
	}
}