import type { OrderInfo } from './payment-types';
import type { CardInformation } from '@cognitoforms/types/server-types/payment/model/card-information';
import type { CultureInfo, Entity, Property, EntityOfType, Type, EntityConstructorForType, EntityDynamicPropertiesOfType } from '@cognitoforms/model.js';
import type { Fee } from '@cognitoforms/types/server-types/payment/model/fee';
import type { TransactionFee } from '@cognitoforms/types/server-types/payment/model/transaction-fee';
import type { LineItem } from '@cognitoforms/types/server-types/payment/model/line-item';
import type { Order } from '@cognitoforms/types/server-types/payment/model/order';
import type { FormEntryWithOrder, LineItemMetaData } from 'src/framework/model/extensions/form-entry-extensions';
import type { Country } from '@cognitoforms/types/server-types/model/country';
import { roundToPrecision } from './helpers';
import type { Choice } from '@cognitoforms/types/server-types/model/choice';
import type { CustomerInformation } from '@cognitoforms/types/server-types/model/customer-information';
import type { RepeatingSectionOrTable } from '../model/core';
import type { OrderExtensions } from '../model/extensions/payment-extensions';

interface GroupedLineItem extends LineItem {
	GroupId: string;
}

interface StripeProcessorFeeProperties extends TransactionFee {
	Country?: Country;
	Mode?: number;
}

type BillingFieldMap = {
	[P in keyof CustomerInformation]?: string;
}

type LineItemMetadataExtensions = {
	propertyList?: Property[];
}

type RebuildOrderOptions = {
	requirePayment?: boolean;
}

export default class OrderBuilder {
	// -------------- Properties --------------
	entry: EntityOfType<FormEntryWithOrder>;

	lineItemMetaData: (LineItemMetaData & LineItemMetadataExtensions)[] = [];

	transactionFees: TransactionFee[] = [];

	processingFees: (TransactionFee & StripeProcessorFeeProperties & { Modes?: string[] })[] = [];

	applicationFee: TransactionFee = null;

	requirePayment: boolean;

	domesticCountryCode: string = 'US';

	processingFeeDescription: string = null;

	processorName: string = null;

	europeanCountries: string[];

	processorFeeModes: { [key: string]: number };

	// -------------- Injections --------------
	$culture: CultureInfo;

	$resource: (name: string) => string;

	$namespace: any;

	// -------------- Data --------------
	idIndex: number = 0;

	cardData: CardInformation = null;

	additionalFees: number = 0;

	processorFee: number = 0;

	constructor(entry: EntityOfType<FormEntryWithOrder>, requirePayment?: boolean) {
		const model = entry.meta.type.model;
		this.$namespace = model.$namespace;
		this.$culture = model.$culture;
		this.$resource = model.getResource.bind(model);

		this.entry = entry;
		this.lineItemMetaData = entry.Line_Item_Metadata;
		this.transactionFees = entry.Transaction_Fees;
		this.processingFees = entry.Processing_Fees;
		this.applicationFee = entry.Application_Fee;
		this.requirePayment = requirePayment;
		this.domesticCountryCode = entry.Domestic_Country_Code;
		this.processingFeeDescription = entry.Processing_Fee_Description;
		this.europeanCountries = entry.European_Countries;
		this.processorFeeModes = entry.Processor_Fee_Modes;
	}

	// -------------- Computed --------------
	get billingFields(): EntityDynamicPropertiesOfType<CustomerInformation> {
		if (!this.billingFieldPaths)
			return null;

		const values = {};

		Object.keys(this.billingFieldPaths).forEach((billingField: keyof EntityDynamicPropertiesOfType<CustomerInformation>) => {
			const path = this.billingFieldPaths[billingField];
			if (!path)
				return;

			const paths = path.split('.');
			let parentEntity = this.entry;
			let property = null;

			// Find the property and the parent instance
			for (let i = 0; i < paths.length; i++) {
				const p = paths[i];
				// Can not be in a dynamic container
				property = parentEntity.meta.type.getPath(p);

				if (i !== paths.length - 1)
					parentEntity = parentEntity ? parentEntity[p] : null;

				// If the parent is unset/empty (Lookup), return
				if (!parentEntity)
					return;
			}

			const val = property.value(parentEntity);
			if (val)
				values[billingField] = val;
		});

		return values as EntityDynamicPropertiesOfType<CustomerInformation>;
	};

	get billingFieldPaths(): BillingFieldMap {
		return {
			Name: this.entry.Billing_Name_Field,
			Email: this.entry.Billing_Email_Field,
			Address: this.entry.Billing_Address_Field,
			Phone: this.entry.Billing_Phone_Field
		};
	}

	get subtotal(): number {
		return this.sum(this.lineItems, 'Amount');
	};

	get fees(): Fee[] {
		if (!this.transactionFees && !this.processingFees)
			return null;

		const subtotal = this.subtotal;

		// Fees should not be present if there is no money due
		if (subtotal <= 0)
			return [];

		// Parse the transaction fees list
		const fees = [] as Fee[];

		// Calculate the amount for each fee and the display value
		this.transactionFees.forEach((tFee) => {
			if (!tFee.Description)
				return;

			let amount = tFee.FixedAmount || 0;
			if (tFee.PercentageAmount) {
				amount += this.roundCurrency(subtotal * tFee.PercentageAmount);
			}
			fees.push({
				Name: tFee.Description,
				Amount: amount,
				Description: tFee.Description,
				IsProcessingFee: false
			});
		});

		this.additionalFees = this.sum(fees, 'Amount');

		// Calculate the amount for each processing fee, the display value, and set the description
		const processingFee = this.calculateProcessingFee();
		if (processingFee) {
			fees.push(processingFee);
		}

		return fees;
	};

	get orderAmount(): number {
		return this.subtotal + this.sum(this.fees, 'Amount');
	};

	get lineItems() : LineItem[] {
		return this.updateLineItems();
	}

	// -------------- Methods --------------
	getOrderInfo() : OrderInfo {
		const orderInfo = {} as OrderInfo;

		Object.keys(this.billingFields).forEach((key) => {
			orderInfo[key] = this.billingFields[key];
		});

		orderInfo.Amount = this.orderAmount;

		return orderInfo;
	}

	updateLineItems() : LineItem[] {
		let lineItems = [] as GroupedLineItem[];

		this.lineItemMetaData.forEach((lineItemData) => {
			const lst = this.entry.meta.type.getPath(lineItemData.path);
			lineItemData.propertyList = lst['properties'] ? lst['properties'] as Property[] : [lst as Property];
		});

		this.lineItemMetaData.forEach((lineItemData) => {
			const propertyList: Property[] = lineItemData.propertyList.slice();

			this.idIndex = 0;

			// Create the line items for the property
			const propertyLineItems = this.generateLineItems(lineItemData, propertyList, this.entry, '', '');

			// If line items were created, add the line items to the list
			if (propertyLineItems) {
				lineItems = lineItems.concat(propertyLineItems);
			}
		});

		lineItems = lineItems.sort((item1, item2) => { return this.idCompare(item1.GroupId.split('-'), item2.GroupId.split('-')); });

		// Line items should appear in the order they appear on the form unless they have a group
		// Grouped line items should appear in the order they appear on the form
		// The group is placed where it is first encountered
		return lineItems.map((gLineItem) => {
			return {
				Name: gLineItem.Name,
				Description: gLineItem.Description,
				Group: gLineItem.Group,
				Amount: gLineItem.Amount ? gLineItem.Amount : 0
			};
		});
	}

	idCompare(item1: string[], item2: string[]) : number {
		const i1 = parseInt(item1[0]) || -1;
		const i2 = parseInt(item2[0]) || -1;
		if (i1 === i2)
			return i1 === -1 ? 0 : this.idCompare(item1.slice(1), item2.slice(1));
		return i1 > i2 ? 1 : -1;
	}

	// Create line items for the given property list
	generateLineItems(lineItemData: LineItemMetaData, propertyList: Property[], parentEntity: Entity, group: string, groupId: string) : GroupedLineItem[] {
		const property = propertyList[0];

		// If the field is always hidden, its value should be constant to differentiate it from conditionally visible fields
		let isConstant = false;
		const visibleProp = parentEntity.meta.type.getProperty(`${property.path}_Visible`);
		if (visibleProp)
			isConstant = visibleProp.isConstant;

		// Conditionally hidden fields do not generate line items
		if (parentEntity[`${property.path}_Visible`] === false && !isConstant && !parentEntity[`${property.path}_IncludeInInvoice`])
			return [];

		// Non-container, leaf property
		if (propertyList.length === 1) {
			// TODO: For choice checkboxes, amount property doesn't look right
			return this.createLineItems(lineItemData, propertyList, parentEntity, group, groupId);
		}

		this.idIndex++;
		const instance = parentEntity[property.path];
		let lineItems = [] as GroupedLineItem[];

		// Dynamic container (ie: repeating section)
		if (Array.isArray(instance)) {
			lineItems = this.createListLineItems(instance, lineItemData, propertyList, parentEntity, group, groupId);
		}
		else {
			propertyList = propertyList.slice(1);
			// Non-dynamic container (ie: section)
			lineItems = this.generateLineItems(lineItemData, propertyList, instance, group, groupId);
		}
		this.idIndex--;
		return lineItems;
	}

	// Create line items for a dynamic container (ie: repeating section)
	createListLineItems(entityList: EntityOfType<RepeatingSectionOrTable>[], lineItemData: LineItemMetaData, propertyList: Property[], parentEntity: Entity, group: string, groupId: any) : GroupedLineItem[] {
		let containerLineItems = [] as GroupedLineItem[];
		const listProp = propertyList[0];
		const itemLabel = parentEntity[`${listProp.path}_ItemLabel`];

		if (!groupId)
			groupId = lineItemData.id.slice();

		const groupIdLastHalf = groupId.splice(this.idIndex);
		this.idIndex++;

		// Create line items for each item in the container
		for (const instance of entityList) {
			group = (itemLabel || 'Item') + ' ' + instance.ItemNumber;
			// Add the item number to the group id so that sorting will factor in the item number
			const itemGroupId = groupId.concat([instance.ItemNumber]).concat(groupIdLastHalf);
			const lineItems = this.generateLineItems(lineItemData, propertyList.slice(1), instance, group, itemGroupId);
			if (lineItems) {
				containerLineItems = containerLineItems.concat(lineItems);
			}
		}

		this.idIndex--;

		return containerLineItems;
	}

	// Create line items for a single property
	createLineItems(lineItemData: LineItemMetaData, propertyList: Property[], parentEntity: Entity, group: string, groupId: any) : GroupedLineItem[] {
		const property = propertyList[0];

		const parentType = parentEntity.meta.type;
		// Get the name property if it exists
		const name = this.getLineItemName(lineItemData, property, parentEntity, parentType);

		if (!groupId) {
			groupId = lineItemData.id;
		}

		groupId = groupId.join('-');

		if (!property.isList) {
			const lineItem = this.createLineItem(property, lineItemData, name, group, groupId, parentEntity, parentType);
			return lineItem ? [lineItem] : [];
		}

		const selectedChoicesProperty = parentEntity.meta.type.getProperty(property.path);
		if (!selectedChoicesProperty)
			return;

		const selectedChoices = selectedChoicesProperty.value(parentEntity) as any[];

		if (lineItemData.type === 'Lookup') {
			return this.createLookupCheckboxLineItems(lineItemData, selectedChoices, name, group, groupId);
		}
		else if (lineItemData.type === 'Choice') {
			return this.createChoiceCheckboxLineItems(lineItemData, selectedChoices, name, group, groupId, parentEntity, parentType);
		}
	}

	createLineItem(property: Property, lineItemData: LineItemMetaData, name: string, group: string, groupId: string, parentEntity: Entity, parentType: Type): GroupedLineItem {
		const amountProperty = lineItemData.amountProperty ? parentType.getProperty(lineItemData.amountProperty) : property;
		const amount = amountProperty.value(parentEntity);

		// Create the description
		const description = this.getLineItemDescription(lineItemData, property, parentEntity, parentType);

		// Negative line items should not be created (unless allowed)
		const lineItemIsNegative = (amount < 0 && !lineItemData.allowNegatives);

		// $0 line items should not be created for non-Choice/Lookup/YesNo fields
		const zeroDollarLineItem = (!amount && !['Choice', 'Lookup', 'YesNo'].includes(lineItemData.type));

		// For quantity-specified line items, if the quantity isn't specified don't create the line item
		const hasUnspecifiedQuantity = lineItemData.quantitySelected && (!description || description.indexOf('(') === -1);

		// Choice/Lookups with no value selected should not have line items
		const choiceOrLookupHasNoValue = ['Choice', 'Lookup'].includes(lineItemData.type) && property.value(parentEntity) === null;

		// YesNo fields with a false value should not have line items.
		const yesNoHasFalseValue = ['YesNo'].includes(lineItemData.type) && property.value(parentEntity) === false;

		if (zeroDollarLineItem || lineItemIsNegative || hasUnspecifiedQuantity || choiceOrLookupHasNoValue || yesNoHasFalseValue)
			return null;

		// Return the line item
		return {
			Amount: this.roundCurrency(amount),
			Name: name,
			Group: group,
			Description: description,
			GroupId: groupId
		};
	}

	createLookupCheckboxLineItems(lineItemData: LineItemMetaData, selectedChoices: Entity[], name: string, group: string, groupId: string): GroupedLineItem[] {
		return selectedChoices.map(lookupEntry => {
			const description = lookupEntry.toString(lineItemData.lookup.labelFormat);
			let parent = lookupEntry;

			if (!lineItemData.lookup.pricePath || !parent.meta.type.getPath(lineItemData.lookup.pricePath))
				return null;

			const paths = lineItemData.lookup.pricePath.split('.');

			// Note: The price path can not be in a repeating section
			paths.slice(0, -1).forEach((path) => {
				parent = parent[path];
			});

			const price = parent[paths.slice(-1)[0]];

			return {
				Amount: price,
				Name: name,
				Group: group,
				Description: description,
				GroupId: groupId
			} as GroupedLineItem;
		}).filter(lineItem => !!lineItem);
	}

	createChoiceCheckboxLineItems(lineItemData: LineItemMetaData & LineItemMetadataExtensions, selectedChoices: string[], name: string, group: string, groupId: string, parentEntity: Entity, parentType: Type) : GroupedLineItem[] {
		const property = lineItemData.propertyList[lineItemData.propertyList.length - 1];
		const choicesProperty = parentEntity.meta.type.getProperty(property.path + '_Choices');

		if (!choicesProperty)
			return [];

		const choiceOptions = choicesProperty.value(parentEntity) as Choice[];

		return selectedChoices.map(label => {
			const choiceOption = choiceOptions.find(c => c.Label === label);

			if (choiceOption)
				return {
					Amount: choiceOption.Price,
					Name: name,
					Group: group,
					Description: label,
					GroupId: groupId
				};
			else
				return null;
		}).filter(item => !!item);
	}

	// Get the description for a line item
	getLineItemDescription(lineItemData: LineItemMetaData, property: Property, parentEntity: Entity, parentType: Type): string {
		let description = '';

		if (lineItemData.descriptionProperty)
			description = parentEntity[lineItemData.descriptionProperty] || '';
		else if (lineItemData.type === 'Choice' || lineItemData.type === 'Lookup')
			description = parentEntity.toString(`[${property.path}]`);

		if (lineItemData.quantitySelected) {
			const quantitySelected = parentType.getProperty(lineItemData.quantitySelected);
			const quantityValue = quantitySelected ? quantitySelected.value(parentEntity) : null;
			if (quantityValue)
				description += ' (' + quantityValue + ')';
			else
				return null;
		}

		return description.trim();
	}

	getLineItemName(lineItemData: LineItemMetaData, property: Property, parentEntity: Entity, parentType: Type): string {
		if (lineItemData.nameProperty) {
			const nameProperty = parentType.getProperty(lineItemData.nameProperty);
			if (nameProperty)
				return nameProperty.value(parentEntity);
		}

		if (property.label) {
			if (property.labelIsFormat) {
				if (lineItemData.isWithinTable) {
					// Labels for fields in tables are evaulated in the table's parent context (since the labels are used as column headers)
					const tableParentEntity = parentEntity['ParentSection'] || parentEntity['Form'];
					return tableParentEntity.toString(property.label);
				}
				else {
					return parentEntity.toString(property.label);
				}
			}
			else {
				return property.label;
			}
		}
	}

	calculateProcessingFee(): Fee {
		const subtotal = this.roundCurrency(this.subtotal + this.additionalFees);
		let processingFees = [] as TransactionFee[];

		if (!subtotal || !this.processingFees || !(this.requirePayment || this.entry.Require_Payment || this.entry.Save_Customer_Card))
			return null;

		const processingFeeDescription = this.processingFeeDescription ? this.processingFeeDescription : this.$resource('payment-processing-fees');

		const fee: Fee = {
			Name: processingFeeDescription,
			Amount: 0,
			Description: processingFeeDescription,
			IsProcessingFee: true
		};

		if (this.processingFees.length > 1) {
			const cardData = this.entry.Entry.PaymentToken ? this.entry.Entry.PaymentToken.Card as CardInformation : null;
			if (!cardData) {
				// If there is no card data when using the Stripe Element, assume the largest of the fees
				processingFees.push(this.getMaximumFee());
			}
			else
				processingFees.push(this.getCardFee(cardData));
		}
		else
			processingFees = this.processingFees;

		if (!processingFees.length && !this.applicationFee)
			return null;

		const fees = {
			processing: {
				percent: this.sum(processingFees, 'PercentageAmount'),
				fixed: this.sum(processingFees, 'FixedAmount')
			},
			application: {
				percent: this.applicationFee ? (this.applicationFee.PercentageAmount || 0) : 0,
				fixed: this.applicationFee ? (this.applicationFee.FixedAmount || 0) : 0
			}
		};

		let feeAmt = this.roundCurrency(subtotal + fees.processing.fixed + fees.application.fixed);
		feeAmt = this.roundCurrency(feeAmt / (1 - (fees.processing.percent + fees.application.percent))) - subtotal;
		const totalWithFees = subtotal + feeAmt;

		const processorFee = this.roundCurrency(totalWithFees * fees.processing.percent + fees.processing.fixed);
		const applicationFee = this.applicationFee ? this.roundCurrency(totalWithFees * fees.application.percent + fees.application.fixed) : 0;

		fee.Amount = applicationFee + processorFee;

		this.processorFee = fee.Amount;

		// Calculate the amount for each processing fee, the display value, and set the description
		return fee;
	}

	getMaximumFee() {
		const subtotal = this.roundCurrency(this.subtotal + this.additionalFees);

		let maxFee = null;
		let maxFeeAmount = 0;

		this.processingFees.forEach(fee => {
			const feeAmount = fee.FixedAmount + fee.PercentageAmount * subtotal;

			if (feeAmount > maxFeeAmount) {
				maxFee = fee;
				maxFeeAmount = feeAmount;
			}
		});

		return maxFee;
	}

	getCardFee(cardData: CardInformation): TransactionFee {
		if (!this.processingFees)
			return null;

		const isAmex = cardData.Brand === 'amex';
		const isEuropean = this.europeanCountries.indexOf(cardData.Country) >= 0;
		const isInternational = this.domesticCountryCode !== cardData.Country;

		const fees = this.processingFees.filter((fee) => {
			if (!fee.Modes)
				fee.Modes = this.getFlags(fee.Mode, this.processorFeeModes);

			if (fee.Modes.indexOf('Any') >= 0)
				return true;

			if (isAmex && fee.Modes.indexOf('AMEX') >= 0)
				return true;

			if (!isAmex && fee.Modes.indexOf('NonAMEX') >= 0)
				return true;

			if (isEuropean && fee.Modes.indexOf('EuropeanCards') >= 0)
				return true;

			if (!isEuropean && fee.Modes.indexOf('NonEuropeanCards') >= 0)
				return true;

			if (isInternational && fee.Modes.indexOf('International') >= 0)
				return true;

			if (!isInternational && fee.Modes.indexOf('Domestic') >= 0)
				return true;

			return false;
		});

		if (fees.length === 1)
			return fees[0];

		// These fees take priority over other fees. For example, if the card is AMEX and Domestic, there will be two fees. However,
		// the AMEX fee is higher priority than the Domestic fee
		const priorityFees = fees.filter((fee) => { return fee.Modes.indexOf('AMEX') >= 0 || fee.Modes.indexOf('International') >= 0 || fee.Modes.indexOf('EuropeanCards') >= 0;});

		return priorityFees.length >= 1 ? priorityFees[0] : fees[0];
	}

	getFlags(value: number, options: { [key: string]: number }): string[] {
		const flags = [];
		if (value === 0)
			return flags;

		// Keys from smallest value to largest value
		const keys = Object.keys(options).sort((k1, k2) => { return options[k1] > options[k2] ? 1 : -1; });

		for (let i = keys.length - 1; i >= 0; i--) {
			const key = keys[i];
			const val = options[key];
			if (value >= val) {
				flags.push(key);
				value -= val;
			}
		}

		return flags;
	}

	roundCurrency(num: number): number {
		return roundToPrecision(num, this.$culture.numberFormat.CurrencyDecimalDigits);
	}

	sum(lst: any[], property: string): number {
		if (lst.length === 0)
			return 0;

		return lst.length === 1 ? lst[0][property] : lst.map((f) => { return f[property];}).reduce((f1, f2) => { return f1 + f2;}, 0);
	}
}

export function rebuildOrder(entry: EntityOfType<FormEntryWithOrder>, order: EntityOfType<Order & OrderExtensions>, rebuildOrderOptions?: RebuildOrderOptions) {
	if (!order || order.AmountPaid) {
		return false;
	}

	const Fee = (entry.meta.type.model.$namespace as any).Payment.Fee as EntityConstructorForType<Fee>;
	const LineItem = (entry.meta.type.model.$namespace as any).Payment.LineItem as EntityConstructorForType<LineItem>;

	const orderBuilder = new OrderBuilder(entry, rebuildOrderOptions?.requirePayment);

	order.LineItems.splice(
		0,
		order.LineItems.length,
		...orderBuilder.lineItems.map(d => new LineItem(d))
	);

	order.Fees.splice(0, order.Fees.length, ...orderBuilder.fees.map(d => new Fee(d)));

	const billingFields = orderBuilder.billingFields;
	order.update('BillingName', billingFields.Name);
	order.update('EmailAddress', billingFields.Email);
	order.update('BillingAddress', billingFields.Address);
	order.update('PhoneNumber', billingFields.Phone);

	order.Rebuild_Count += 1;

	return true;
}
