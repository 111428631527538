import type { DiffContext } from 'jsondiffpatch';
import { create } from 'jsondiffpatch';
import { addIdsToRepeatingSectionObjects, arrayFilterPreventModificationsFromMissingItems, arraysCollectPreventDuplicateCollections } from './custom-patch-pipes';

const diffPatcher = create({
	propertyFilter: (propertyName: string, context: DiffContext) => {
		// Don't include changes from null to null
		// eslint-disable-next-line eqeqeq
		if (context.left[propertyName] == null && context.right[propertyName] == null)
			return false;
		else if (propertyName.startsWith('$'))
			return false;
		// Remove pseudo properties like Choice_Price since they never get serialized by model.js
		// eslint-disable-next-line eqeqeq
		else if (propertyName.lastIndexOf('_') > 0)
			return false;
		// root properties don't have a child name
		// eslint-disable-next-line eqeqeq
		else if (context.childName == null && ['Entry', 'Form', 'Id', 'Order'].includes(propertyName))
			return false;
		else if (['ItemNumber'].includes(propertyName))
			return false;
		else
			return true;
	},
	// Formatters don't support textDiff
	textDiff: {
		minLength: Number.MAX_SAFE_INTEGER
	},
	// Used to differentiate between array objects
	objectHash: (obj: any) => {
		return obj.Id || JSON.stringify(obj);
	},
	arrays: {
		detectMove: false
	}
});

diffPatcher.processor.pipes.diff.replace('collectChildren', addIdsToRepeatingSectionObjects);
diffPatcher.processor.pipes.patch.replace('arrays', arrayFilterPreventModificationsFromMissingItems);
diffPatcher.processor.pipes.patch.replace('arraysCollectChildren', arraysCollectPreventDuplicateCollections);

export { diffPatcher };