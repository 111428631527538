import type { EntityOfType, Entity, PropertyPath } from '@cognitoforms/model.js';
import type { FormEntryIndex } from '@cognitoforms/types/server-types/forms/model/form-entry-index';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type EntryViewService from './entry-view-service';
import { getEntryId, getTimestamp, keyFromIndex } from './entry-view-util';
import type { EntryData, EntryKey, UserSpecificViewSettings } from './entry-view-types';
import type { UserInfo } from '@cognitoforms/types/server-types/forms/model/user-info';
import { isEntityType } from '@cognitoforms/model.js';
import { compileExpression, getKeywordFunction, getStatusFunction } from './entry-set-filtering';
import type { EntryViewFilter } from '@cognitoforms/types/server-types/forms/model/entry-view-filter';
import type { FieldInfo } from '@cognitoforms/types/server-types/model/field-info';
import { Deferred } from 'src/util/deferred';
import type { WorkflowRole } from '@cognitoforms/types/server-types/forms/model/workflow-role';
import { IndexType } from '@cognitoforms/types/server-types/forms/model/index-type';

type FieldInfoExtensions = { property: PropertyPath };
type SortFunction = (entry1: EntityOfType<FormEntry>, entry2: EntityOfType<FormEntry>) => number;

type EntrySetFormEntry = EntityOfType<FormEntry> & { $etag: string };

// Default sorting by server-side sort key
function defaultSort(a: FormEntryIndex, b: FormEntryIndex) {
	const ak = a.SortKey;
	const bk = b.SortKey;
	return ak === bk ? 0 : ak < bk ? -1 : 1;
}

export default class EntrySet {
	private readonly service: EntryViewService;
	private readonly entryType: string;
	private indexes = new Map<string, FormEntryIndex>();
	private entries = new Map<string, EntryData>();
	readonly formId: string;
	readonly view: string;
	readonly userShort: string;
	readonly role: WorkflowRole;
	readonly user: UserInfo;
	private timestamp: string = '';
	private filterCriteria: EntryViewFilter;
	private sortCriteria: SortFunction;
	/**
	 * Array of FormEntryIndex objects that this entry set contains, order matches that of Grid UI.
	 */
	private indexList: FormEntryIndex[];
	/**
	 * Mapping of entry ID to the corresponding entry's position in `indexList`.
	 */
	private entryPosition: Map<string, number>;
	/**
	 * An optional entry set. Used to fetch the full unfiltered set of entries,
	 * and to determine if current entry set is filtered: If `unfilteredParent` is defined, `this` entry set is filtered.
	 */
	unfilteredParent: EntrySet;

	private constructor(
		viewService: EntryViewService,
		entryType: string,
		view: string,
		role?: WorkflowRole,
		user?: UserInfo,
		userShort?: string,
		filterCriteria?: EntryViewFilter,
		unfilteredParent?: EntrySet
	) {
		this.service = viewService;
		this.entryType = entryType;
		this.formId = view.split('-')[0];
		this.view = view;
		this.role = role;
		this.user = user;
		this.userShort = userShort;
		this.filterCriteria = filterCriteria;
		this.unfilteredParent = unfilteredParent;
	}

	private static instances = new Map<string, EntrySet>();
	static async get(viewService: EntryViewService, entryType: string, view: string, role?: WorkflowRole, user?: UserInfo, userShort?: string) {
		let key = `${viewService.id}::${view}`;
		if (userShort && role)
			key += `::${userShort}::${role.Id}`;
		const instance = EntrySet.instances.get(key) || new EntrySet(viewService, entryType, view, role, user, userShort);
		this.instances.set(key, instance);
		await instance.initializeFromCache();
		return instance;
	}

	private get isUserSpecific() {
		return !!this.userShort;
	}

	private get userSpecificSettings(): UserSpecificViewSettings {
		return this.isUserSpecific
			? { userShort: this.userShort, roleId: this.role.Id }
			: null;
	}

	private refreshPromise: Promise<void|EntrySet>;

	// Refresh entry set
	async refresh(progressCallback?: (progress: number, interval: number) => void): Promise<void|EntrySet> {
		if (this.refreshPromise)
			return this.refreshPromise;

		const task = new Deferred();
		this.refreshPromise = task.promise;

		// If current instance is a filtered set, refresh will fetch indexes unnecessarily
		if (this.unfilteredParent) {
			// Refresh the parent, refilter it, and return the filtered child entry set
			task.resolve(await this.unfilteredParent.filter(this.filterCriteria, this.sortCriteria));
		}
		// If current instance uses a server-side filter or is unfiltered, refresh without refiltering
		else {
			const indexes = await this.service.getIndex(this.formId, this.view, this.userSpecificSettings, this.timestamp, progressCallback);
			this.compileTransactionalIndexes(indexes);
			await this.cacheIndexes();
			await this.sort(this.sortCriteria);
			task.resolve();
		}
		this.refreshPromise = null;
		return task.promise;
	}

	private pollEntryPromise: Promise<void>;

	// Poll for entry changes
	async pollEntry(entryId: string): Promise<void> {
		if (this.pollEntryPromise)
			return this.pollEntryPromise;

		const task = new Deferred<void>();
		this.pollEntryPromise = task.promise;

		// If current instance is a filtered set, poll entry in parent and return refiltered child
		if (this.unfilteredParent) {
			await this.unfilteredParent.filter(this.filterCriteria, this.sortCriteria, this, entryId);
		}
		// If current instance uses a server-side filter or is unfiltered, refresh without refiltering
		else {
			const indexes = await this.service.getSpecificIndex(entryId, this.formId, this.view, this.userSpecificSettings, this.timestamp);
			this.compileTransactionalIndexes(indexes);
			await this.cacheIndexes();
			await this.sort(this.sortCriteria);
		}
		task.resolve();
		this.pollEntryPromise = null;
		return task.promise;
	}

	private async cacheIndexes() {
		return this.service.cacheIndexes(
			this.formId,
			this.view,
			Array.from(this.indexes.values()),
			this.timestamp,
			this.userSpecificSettings);
	}

	private async initializeFromCache() {
		const cachedIndexes = await this.service.loadCachedIndexes(this.formId, this.view, this.userSpecificSettings);
		if (cachedIndexes) {
			this.indexes = new Map(cachedIndexes.entries.map(index => [getEntryId(index), index]));
			this.timestamp = cachedIndexes.timestamp;
		}
	}

	// Compiles "transactional" (add/update/delete/etc.) indexes into the actual list of indexes representing entries which are in this entry set
	private compileTransactionalIndexes(indexes: FormEntryIndex[]) {
		let latestTimestamp = this.timestamp;
		let atobErrorLogged = false;

		for (const index of indexes) {
			const id = getEntryId(index);

			// Track the most recent timestamp
			if (getTimestamp(index) > latestTimestamp) {
				latestTimestamp = getTimestamp(index);
			}

			// Clear the cache and start over when a checkpoint is encountered
			if (index.Type === IndexType.Checkpoint || index.Type === IndexType.Flush) {
				this.indexes.clear();
			}
			// Add items to the index that are being added or updated
			else if (index.Type === IndexType.Add || index.Type === IndexType.Update) {
				// Decode the sort key
				if (!index['sortDecoded'] && index.SortKey !== undefined) {
					try {
						index.SortKey = atob(index.SortKey);
					}
					catch (e) {
						!atobErrorLogged && console.log(`Client-side atob error - error: ${e}, sortKey: ${index.SortKey}`);
						atobErrorLogged = true;
					}

					index['sortDecoded'] = true;
				}

				this.indexes.set(id, index);
				this.validateCachedEntry(id, index);
			}
			else if (index.Type === IndexType.Delete) {
				this.indexes.delete(id);
				this.entries.delete(id);
			}
		}

		this.timestamp = latestTimestamp;
	}

	private filterPromise: Promise<EntrySet>;

	/**
	 * Apply filter to an unfiltered entry set.
	 * The caller (`this`) must contain a full set of entries (view `{formId}-0`) to remove effect of server-side filters.
	 * @param filter Plain JS object representing the filter
	 * @param sortCriteria Sort criteria to apply to the filtered set
	 * @param entryId Entry to poll for changes to (only applicable when polling a filtered set)
	 * @returns An entry set containing a filtered subset of the caller entry set
	 */
	async filter(filter: EntryViewFilter, sortCriteria: SortFunction, filteredSet?: EntrySet, entryId?: string): Promise<EntrySet> {
		// Use filterPromise as a "lock" to queue multiple filter calls
		const filterTask = new Deferred<EntrySet>();
		while (this.filterPromise)
			await this.filterPromise;

		this.filterPromise = filterTask.promise;

		const filterFn = await this.getFilterFunction(filter);

		// If filterFn is undefined, return the current unfiltered entry set
		if (!filterFn) {
			this.sortCriteria = sortCriteria;
			await this.refresh();
			this.filterPromise = null;
			return this;
		}

		// Store and clear the original sort criteria to avoid sorting the original entry set before applying the filter
		this.sortCriteria = null;
		if (entryId) {
			await this.pollEntry(entryId);
		}
		else {
			await this.refresh();
		}
		await this.loadAllEntries();

		if (!filteredSet)
			filteredSet = new EntrySet(this.service, this.entryType, this.view, this.role, this.user, this.userShort, filter, this);

		filteredSet.timestamp = null;
		filteredSet.entries.clear();
		filteredSet.indexes.clear();

		// Add entries that match the filter
		for (const [id, index] of this.indexes) {
			if (filterFn.call(await this.getEntry(index))) {
				filteredSet.indexes.set(id, index);
				if (this.entries.has(id))
					filteredSet.entries.set(id, this.entries.get(id));
			}
		}

		// Restore the sort criteria for the original entry set
		this.sortCriteria = sortCriteria;

		// Sort the filtered set
		await filteredSet.sort(sortCriteria);

		// Release the filter promise "lock"
		filterTask.resolve(filteredSet);
		this.filterPromise = null;
		return filteredSet;
	}

	/**
	 * Gets a Javascript function that can be used to filter entries on the client
	 */
	private async getFilterFunction(filter: EntryViewFilter) {
		if (filter.Invalid)
			return function () { return false; };

		// Keyword Filter
		const keywordFn = getKeywordFunction(filter);

		// Custom Filter
		if (filter.Expression) {
			const fn = await this.service.getFilter(this.formId, filter);
			const filterExpression = compileExpression(fn, this.service.model.modelOptions.$namespace);
			const customFilter = function (this: Entity) {
				try { return filterExpression.call(this); }
				catch (e) { return false; }
			};
			if (keywordFn)
				return function (this: Entity) { return customFilter.call(this) && keywordFn.call(this); };
			else
				return customFilter;
		}

		// Entry/Payment Status Filter
		const statusFn = getStatusFunction(filter, `Cognito.${this.entryType}`);

		// Combine keyword and status filter functions, as appropriate
		if (keywordFn && statusFn)
			return function (this: Entity) { return statusFn.call(this) && keywordFn.call(this); };
		else if (keywordFn)
			return keywordFn;
		else if (statusFn)
			return statusFn;
		else
			return null;
	}

	/**
	 * Adds `model.js` property chains to model `fieldInfos`
	 */
	getPropertyChains(fieldInfos: (EntityOfType<FieldInfo> & Partial<FieldInfoExtensions>)[]): (EntityOfType<FieldInfo> & FieldInfoExtensions)[] {
		const formEntryType = this.service.model.resolveType(this.entryType);
		fieldInfos.forEach(fieldInfo => {
			const propertyPath = formEntryType.getPath(fieldInfo.Path);
			fieldInfo.property = propertyPath;
		});
		return fieldInfos as (EntityOfType<FieldInfo> & FieldInfoExtensions)[];
	}

	size(): number {
		return this.indexes.size;
	}

	// Exists to provide access to isEntityType to gridview.js
	private isEntityType(propertyType: any): boolean {
		return isEntityType(propertyType);
	}

	/**
	 * Sorts the entry set using the specified criteria
	 * @param sortCriteria JS function representing the sort criteria, parameter is optional (for unsorted entry sets)
	 */
	async sort(sortCriteria?: SortFunction): Promise<void> {
		this.indexList = Array.from(this.indexes.values());
		// If no sortCriteria, sort immediately using the server sort key
		if (!sortCriteria) {
			this.indexList.sort(defaultSort);
			this.rebuildEntryPosition();
		}
		// Otherwise, sort asynchronously using client sort criteria
		else {
			this.sortCriteria = sortCriteria;
			await this.loadAllEntries();
			this.indexList.sort((index1, index2) => {
				return this.sortCriteria(this.getCachedEntry(getEntryId(index1)), this.getCachedEntry(getEntryId(index2)));
			});
			this.rebuildEntryPosition();
		}
	}

	getSortCriteria() {
		return this.sortCriteria;
	}

	/**
	 * Reconciles `entryPosition` (map of entry => grid row) with the newly sorted `indexList`
	 */
	private rebuildEntryPosition() {
		this.entryPosition = new Map<string, number>(this.indexList.map((idx, pos) => [getEntryId(idx), pos]));
	}

	listIndex(): FormEntryIndex[] {
		return this.indexList;
	}

	indexOfEntryId(entryId: string): number {
		return this.entryPosition.get(entryId);
	}

	private entryIndexAt(index: number): FormEntryIndex {
		return this.indexList[index];
	}

	/**
	 * Fetches entry key located at a specified row in the entries grid
	 */
	entryKeyAt(index: number): EntryKey {
		const entryIndex = this.entryIndexAt(index);
		return keyFromIndex(entryIndex);
	}

	/**
	 * Fetches entry located at a specified row in the entries grid
	 */
	entryAt(index: number, callback: (entry: EntityOfType<FormEntry>) => void): EntityOfType<FormEntry> | null {
		const entryIndex = this.entryIndexAt(index);
		const id = keyFromIndex(entryIndex).EntryId;
		const typeCacheEntry = this.getCachedEntry(id);
		// If entry is cached, it can be fetched synchronously for SlickGrid use
		if (typeCacheEntry) {
			return typeCacheEntry;
		}
		// If asynchronous then after promise resolves, perform callback to rerender SlickGrid row
		else {
			this.getEntry(entryIndex).then(callback);
			return null;
		}
	}

	getIndexItem(id: string): FormEntryIndex {
		return this.indexes.get(id);
	}

	private async loadAllEntries(): Promise<FormEntry[]> {
		const entryPromises = [];
		for (const index of this.indexes.values()) {
			entryPromises.push(this.getEntry(index));
		}
		return Promise.all(entryPromises);
	}

	private validateCachedEntry(entryId: string, index: FormEntryIndex) {
		// for filtered sets, this.entries is empty because the entries were pulled from type cache.
		// deserialized entry instances don't have an $etag property with which to compare.
		const cachedEntry = this.entries.get(entryId) || this.getCachedEntry(entryId);
		if (cachedEntry && cachedEntry.$etag !== index.EntryETag) {
			this.entries.delete(entryId);
			this.service.model.resetEntry('Forms.FormEntry', entryId);
		}
	}

	private getCachedEntry(id: string): EntrySetFormEntry {
		const entry = this.service.model.model.types['Forms.FormEntry'].get(id) as EntrySetFormEntry;

		if (entry && !entry.$etag)
			console.warn('A FormEntry is cached in the model without an $etag. This could lead to interaction with stale data. Id=', id);

		// if the cached entry has a different role than the current entry set, it needs to be reconstructed
		if (entry && this.role && this.role.Name !== entry.Entry.Role)
			return null;

		return entry;
	}

	private async constructEntry(state: EntryData): Promise<EntrySetFormEntry> {
		const id = state.Id;
		const typeCacheEntry = this.getCachedEntry(id);
		let entry: EntityOfType<FormEntry> = null;

		if (typeCacheEntry) {
			entry = typeCacheEntry;
		}
		else {
			// use sync construction to ensure we can mark the entry with an etag before anyone else pulls it from the type cache
			entry = this.service.model.construct<FormEntry>(this.entryType, state as any, true);
			(entry as EntrySetFormEntry).$etag = state.$etag;
		}

		await entry.initialized;
		return entry as EntrySetFormEntry;
	}

	async getEntry(index: EntityOfType<FormEntryIndex> | FormEntryIndex): Promise<EntityOfType<FormEntry>> {
		const id = getEntryId(index);
		const typeCacheEntry = this.getCachedEntry(id);
		if (typeCacheEntry && typeCacheEntry.$etag === index.EntryETag)
			return typeCacheEntry.initialized.then(() => typeCacheEntry);

		const entryJson = await this.getEntryJson(keyFromIndex(index));
		const entry = await this.constructEntry(entryJson);
		return entry;
	}

	async getEntryByKey(entryKey: EntryKey, isAdmin: boolean = false): Promise<EntityOfType<FormEntry>> {
		const entryJson = await this.getEntryJson(entryKey, isAdmin);
		return this.constructEntry(entryJson);
	}

	private async loadEntryData(entryKeys: EntryKey[], isAdmin: boolean = false) {
		const entries = await this.service.loadEntryData(this.view, isAdmin, ...entryKeys);

		for (const e of entries) {
			if (e.Entry) {
				e.Entry.User = this.user;
				e.Entry.Role = this.role?.Name;
			}
			this.entries.set(e.Id, e);
		}
	}

	async getEntryJson(entryKey: EntryKey, isAdmin: boolean = false): Promise<EntryData> {
		const id = entryKey.EntryId;
		const entryData = this.entries.get(id);
		if (entryData && entryData.$etag === entryKey.EntryETag)
			return entryData;

		await this.loadEntryData([entryKey], isAdmin);
		return this.entries.get(id);
	}
}