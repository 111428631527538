export function randomInt(max: number = 9) {
	// https://learn.freecodecamp.org/javascript-algorithms-and-data-structures/basic-javascript/generate-random-whole-numbers-with-javascript/
	const scaleFactor = max + 1;
	return Math.floor(Math.random() * scaleFactor);
}

export function randomText(length: number = 1, includeDigits: boolean = false): string {
	let str: string = '';
	while (str.length < length) {
		const remainingLength = length - str.length;
		// https://gist.github.com/6174/6062387
		let chunk = Math.random().toString(36).substring(2);
		if (!includeDigits) chunk = chunk.replace(/[0-9]/g, '');
		const chunkLength = Math.min(chunk.length, remainingLength);
		str += chunk.substr(0, chunkLength);
	}
	return str;
}
