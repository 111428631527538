import type { EntrySaveResult, EntrySubmissionResult, ResumeEntryResult } from './entry-service';
import { EntryService, parseStoreResult, SubmissionResultStatus } from './entry-service';
import type { FormEntryWithOptionalOrder } from 'src/framework/model/extensions/form-entry-extensions';
import type { Delta } from 'jsondiffpatch';
import type { ServiceRequestError, ServiceRequestResult } from './base-service';
import { CustomResponseError } from './custom-response-error';
import type { PaymentToken } from '@cognitoforms/types/server-types/payment/model/payment-token';
import { CognitoJsonPatchFormatter } from './diff-patch/CognitoJsonPatchFormatter';
import { diffPatcher } from './diff-patch/CognitoJsonDiffPatcher';
import type { ObjectLookup } from 'src/util';
import { ViewType } from '@cognitoforms/types/server-types/forms/model/view-type';
import type { EntityOfType } from '@cognitoforms/model.js';

const jsonPatchFormatter = new CognitoJsonPatchFormatter();
export class ConcurrentEntryConflict extends Error {
	entry: any;
	order: any;

	constructor(entry: any, order: any) {
		super('Entry Conflict');
		this.entry = entry;
		this.order = order;
	}
}

export default class ConcurrentEntryService extends EntryService {
	initialEntryJson: any = {};
	private isAdmin: boolean = false;
	private viewId: string = undefined;
	private viewType: ViewType = undefined;

	get diffPatcher() {
		return diffPatcher;
	}

	get jsonPatchFormatter() {
		return jsonPatchFormatter;
	}

	constructor(session: any, isAdmin: boolean) {
		super(session);
		this.isAdmin = isAdmin;
	}

	async submit(entry: EntityOfType<FormEntryWithOptionalOrder>, embedUrl: string, entryToken: string): Promise<EntrySubmissionResult> {
		let result;
		let delta;
		const entryJson = entry.serialize({ force: false, useAliases: true }) as any;
		const order = entry.Order;

		if (entry.meta.isNew)
			result = await this.performActionForNewEntry(entry.Entry.Action, entryJson, order, embedUrl);
		else {
			delta = this.diffPatcher.diff(this.initialEntryJson, entryJson);
			const diffPatch = this.jsonPatchFormatter.format(delta);
			result = await this.performActionForExistingEntry(entry.Id, entry.Entry.Version, entry.Entry.Action, diffPatch, order, entryJson.Entry.PaymentToken, embedUrl, entryToken, entry.Entry.Role, entryJson.Entry.User);
		}

		if (result.response && result.response.data) {
			const data = result.response.data;
			const submissionResult = parseStoreResult(data) as EntrySubmissionResult;

			// In the following submission results, the server stored the submitted entry data
			if (submissionResult.status === SubmissionResultStatus.Success
				|| submissionResult.status === SubmissionResultStatus.CardDeclined
				|| submissionResult.status === SubmissionResultStatus.OrderMismatch
				|| submissionResult.status === SubmissionResultStatus.PaymentDeclined)
				this.initialEntryJson = submissionResult.entry;

			submissionResult.entry.Entry.Action = entry.Entry.Action;
			submissionResult.entry.Entry.Role = entry.Entry.Role;

			// Add the session token to document links to support downloads on the public form confirmation page
			if (data.entryDocuments) {
				submissionResult.documents = data.entryDocuments.map(doc => {
					doc.link = doc.link + (this.isAdmin ? '' : '&sessionToken=' + encodeURIComponent(this.sessionToken));
					return doc;
				});
			}

			return submissionResult;
		}

		throw this.parseSubmissionError(result.error, delta, entry.Entry.Action, entry.Entry.Role);
	}

	async save(entry: EntityOfType<FormEntryWithOptionalOrder>, embedUrl: string, entryToken: string, resumePage?: number): Promise<EntrySaveResult> {
		if (!this.session.flags.saveAndResume)
			throw new Error('Entry saving is unsupported.');

		let result;
		let delta;
		const entryJson = entry.serialize({ force: false, useAliases: true }) as any;
		const order = entry.Order;

		if (entry.meta.isNew)
			result = await this.saveNewEntry(entryJson, order, embedUrl, resumePage);
		else {
			delta = this.diffPatcher.diff(this.initialEntryJson, entryJson);
			const diffPatch = this.jsonPatchFormatter.format(delta);
			result = await this.saveExistingEntry(entry.Id, entry.Entry.Version, diffPatch, order, embedUrl, entryToken, resumePage, entryJson.Entry.User, entryJson.Entry.Role);
		}

		if (result.response && result.response.data) {
			const data = result.response.data;
			const saveResult = parseStoreResult(data) as unknown as EntrySaveResult;

			this.initialEntryJson = this.diffPatcher.clone(saveResult.entry);

			saveResult.emailAddress = data.saveAndResumeRecipients;
			saveResult.emailMessage = data.emailMessage;
			saveResult.link = data.link;
			saveResult.entry.Entry.Action = entry.Entry.Action;
			saveResult.entry.Entry.Role = entry.Entry.Role;
			// Avoid loss of changes made while save request was in flight
			const postSaveEntryJson = entry.serialize({ force: false, useAliases: true });
			delta = this.diffPatcher.diff(this.initialEntryJson, postSaveEntryJson);
			if (delta)
				saveResult.entry = this.diffPatcher.patch(saveResult.entry, delta);
			return saveResult;
		}

		throw this.parseSubmissionError(result.error, delta, entry.Entry.Action, entry.Entry.Role);
	}

	async emailResumeLink(entryId: string, recipient: string, message: string, embedUrl: string) {
		const result = await this.serviceRequest({
			method: 'post',
			endpoint: 'svc/save-resume/send-email',
			data: { entryId, recipient, message, embedUrl }
		});

		if (result.error)
			throw this.parseError(result.error);
	}

	async resume(formId: string, entryToken: string): Promise<ResumeEntryResult> {
		const result = await this.serviceRequest({
			method: 'get',
			endpoint: 'svc/resume-entry',
			params: { formId, entryToken }
		});

		if (result.response && result.response.data) {
			// Parse entry JSON into POJO
			result.response.data.entry = JSON.parse(result.response.data.entryJson);
			delete result.response.data.entryJson;
			this.initialEntryJson = result.response.data.entry;
			return result.response.data;
		}

		throw this.parseError(result.error);
	}

	updateInitialEntryJson(entryJson: ObjectLookup<any>) {
		this.initialEntryJson = entryJson;
	}

	registerView(viewId: string, viewType: ViewType = ViewType.Table) {
		this.viewId = viewId;
		this.viewType = viewType;
	}

	parseSubmissionError(error: ServiceRequestError, delta: Delta, action: string, role: string): Error {
		if (error instanceof CustomResponseError && error.data && error.data.Type === 'Entry Conflict') {
			this.initialEntryJson = error.data.Data.entry;

			const patchedEntry = this.diffPatcher.patch(this.diffPatcher.clone(this.initialEntryJson), delta);
			patchedEntry.Entry.Action = action;
			patchedEntry.Entry.Role = role;
			return new ConcurrentEntryConflict(patchedEntry, error.data.Data.order);
		}
		return this.parseError(error);
	}

	private performActionForExistingEntry(entryId: string, entryVersion: number, action: string, patch: any[], order: any, paymentToken: PaymentToken, embedUrl: string, entryToken: string, role?: string, user?:object): Promise<ServiceRequestResult> {
		return this.serviceRequest({
			method: 'post',
			endpoint: 'svc/update-entry/perform-action' + (this.isAdmin ? '/admin' : ''),
			isCognitoJson: true,
			headers: this.getValidationHeaders(),
			data: {
				FormId: this.session.formId,
				EntryId: entryId,
				EntryVersion: entryVersion,
				Action: action,
				Patch: patch,
				EntryToken: entryToken,
				EmbedUrl: embedUrl,
				OrderAmount: order ? order.OrderAmount : null,
				PaymentToken: paymentToken || null,
				Role: this.isAdmin && role ? role : null,
				UserInfo: user,
				IsStoragePatch: true,
				ViewId: this.viewId,
				IsFormView: this.viewType === ViewType.Form
			}
		});
	}

	private performActionForNewEntry(action: string, entry: object, order: any, embedUrl: string): Promise<ServiceRequestResult> {
		return this.serviceRequest({
			method: 'post',
			endpoint: 'svc/update-entry/perform-action/new-entry' + (this.isAdmin ? '/admin' : ''),
			isCognitoJson: true,
			headers: this.getValidationHeaders(),
			data: {
				FormId: this.session.formId,
				Action: action,
				EntryJson: JSON.stringify(entry),
				AccessToken: this.session.accessToken,
				EmbedUrl: embedUrl,
				OrderAmount: order ? order.OrderAmount : null,
				IsStoragePatch: true,
				ViewId: this.viewId,
				IsFormView: this.viewType === ViewType.Form
			}
		});
	}

	private saveNewEntry(entry: object, order: any, embedUrl: string, resumePage?: number): Promise<ServiceRequestResult> {
		return this.serviceRequest({
			method: 'post',
			endpoint: 'svc/update-entry/save/new-entry' + (this.isAdmin ? '/admin' : ''),
			isCognitoJson: true,
			headers: this.getValidationHeaders(),
			data: {
				FormId: this.session.formId,
				EntryJson: JSON.stringify(entry),
				AccessToken: this.session.accessToken,
				EmbedUrl: embedUrl,
				OrderAmount: order ? order.OrderAmount : null,
				LastPageViewed: resumePage,
				IsStoragePatch: true,
				ViewId: this.viewId,
				GenerateAssignment: this.isAdmin && this.viewType === ViewType.Form
			}
		});
	}

	private saveExistingEntry(entryId: string, entryVersion: number, patch: any[], order: any, embedUrl: string, entryToken: string, resumePage?: number, user?: object, role?: string): Promise<ServiceRequestResult> {
		return this.serviceRequest({
			method: 'post',
			endpoint: 'svc/update-entry/save' + (this.isAdmin ? '/admin' : ''),
			isCognitoJson: true,
			headers: this.getValidationHeaders(),
			data: {
				FormId: this.session.formId,
				EntryId: entryId,
				EntryVersion: entryVersion,
				Patch: patch,
				EntryToken: entryToken,
				EmbedUrl: embedUrl,
				OrderAmount: order ? order.OrderAmount : null,
				LastPageViewed: resumePage,
				UserInfo: user,
				Role: role,
				IsStoragePatch: true,
				ViewId: this.viewId,
				GenerateAssignment: this.isAdmin && this.viewType === ViewType.Form
			}
		});
	}
}