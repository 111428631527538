import type { Property, Entity } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';

/**
 * Handles deserialization of JSON date strings
 */
export default class DateTimeConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.propertyType === Date && !['d', 't'].includes(prop.format.specifier);
	}

	deserialize(context: Entity, value: any, prop: Property) {
		if (typeof value === 'string')
			return new Date(value);
		return value;
	}
}