import { formatters } from 'jsondiffpatch';

// Copied from https://github.com/benjamine/jsondiffpatch/blob/master/src/formatters/jsonpatch.js
const isMoveOp = ({ op }) => op === 'move';
const isRemoveOp = ({ op }) => op === 'remove';
const last = arr => arr[arr.length - 1];

const sortBy = (arr, pred) => {
	arr.sort(pred);
	return arr;
};

const compareByIndexDesc = (indexA, indexB) => {
	const lastA = parseInt(indexA, 10);
	const lastB = parseInt(indexB, 10);
	if (!(isNaN(lastA) || isNaN(lastB))) {
		return lastB - lastA;
	}
	else {
		return 0;
	}
};

const opsByDescendingOrder = removeOps => sortBy(removeOps, (a, b) => {
	const splitA = a.path.split('/');
	const splitB = b.path.split('/');
	if (splitA.length !== splitB.length) {
		return splitA.length - splitB.length;
	}
	else {
		return compareByIndexDesc(last(splitA), last(splitB));
	}
});
// end copied code

export class CognitoJsonPatchFormatter extends formatters.jsonpatch.default {
	private static reorderOps(diff) {
		const [moveOps, removedOps, restOps] = formatters.jsonpatch.partitionOps(diff, [isMoveOp, isRemoveOp]);
		const removeOpsReverse = opsByDescendingOrder(removedOps);
		return [...removeOpsReverse, ...moveOps, ...restOps];
	}

	format(delta, left) {
		return CognitoJsonPatchFormatter.reorderOps(super.format(delta, left));
	}

	recurse(context, delta, left, key, leftKey, movedFrom, isLast) {
		if (key !== '$id')
			super.recurse(context, delta, left, key, leftKey, movedFrom, isLast);
	}
}
