import type { Entity, EntityOfType, EntityConstructorForType, Type } from '@cognitoforms/model.js';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { Signature } from '@cognitoforms/types/server-types/model/signature';
import { randomText, randomInt } from 'src/util/random';
import { combineUInt16sWithPadding } from 'src/util/combined-number';
import { toBase62 } from 'src/util/base62';
import type { ObjectLookup } from '@cognitoforms/model.js/@types/helpers';

export type DynamicEntity = {
	Id: string;
};

export type RepeatingSectionOrTable = DynamicEntity & {
	ItemNumber: number;
};

export type SectionOrTable = DynamicEntity & {
	Form: FormEntry;
};

export type NestedSectionOrTable = SectionOrTable & {
	ParentSection: DynamicEntity | FormEntry;
};

function isFormEntry(entity: Entity): entity is EntityOfType<FormEntry> {
	return entity.meta.type.baseType && entity.meta.type.baseType.fullName === 'Forms.FormEntry';
}

function isSectionOrTable(entity: Entity): entity is EntityOfType<SectionOrTable> {
	return !isFormEntry(entity) && 'Form' in entity;
}

function isNestedSectionOrTable(entity: Entity): entity is EntityOfType<NestedSectionOrTable> {
	return 'ParentSection' in entity;
}

export function isSignature(entity: Entity): entity is EntityOfType<Signature> {
	return entity.meta.type.fullName === 'Signature';
}

/**
 * Either set the child's `Form` property, or propogate it from the parent when it is set
 * @param parent The parent entity (either a FormEntry or Section/RepeatingSection/TableRow entity)
 * @param child The child entity
 */
function propagateFormProperty(parent: EntityOfType<DynamicEntity>, child: EntityOfType<SectionOrTable>) {
	// In case the parent's Form property is not yet set, propagate when it is set
	if (isSectionOrTable(parent) && parent.Form)
		child.Form = parent.Form;
	else
		parent.meta.type.getProperty('Form').changed.subscribeOne(e => (child.Form = e.newValue));
}

/**
 * Set "backreference" properties (i.e. `Form` and `ParentSection`) on a child entity
 * @param parent The parent entity (either a FormEntry or Section/RepeatingSection/TableRow entity)
 * @param child The child entity
 */
function setBackReferenceProperties(parent: EntityOfType<FormEntry | DynamicEntity>, child: EntityOfType<SectionOrTable | NestedSectionOrTable>) {
	if (isNestedSectionOrTable(child)) {
		child.ParentSection = parent;
		propagateFormProperty(parent, child);
	}
	else
		child.Form = parent as EntityOfType<FormEntry>;
}

/**
 * Generate an id based on the given entry version and list item index
 * @param {number} version The form entry version to be saved
 * @param {number} listItemIndex The item's index in the list
 * @returns {string} The generated list item Id
 */
function generateListItemId(version: number, listItemIndex: number): string {
	if (version > 0xffff)
		throw new Error('Value ' + version + ' exceeds maximum unsigned short value of ' + 0xffff + '.');

	if (listItemIndex > 0xffff)
		throw new Error('Value ' + listItemIndex + ' exceeds maximum unsigned short value of ' + 0xffff + '.');

	const randomByte1 = randomInt(255);
	const randomByte2 = randomInt(255);
	const num = combineUInt16sWithPadding(version, listItemIndex, randomByte1, randomByte2);
	const id = toBase62(num);
	// console.log(`generateListItemId(${version}, ${listItemIndex}) => ${id}`);
	return id;
}

/**
 * Get a map of list item ids to their index in the list
 * @param list The list of entities which have an Id property
 * @returns A map of id to entity for all of the items in the list
 */
function getListItemIdMap(list: EntityOfType<DynamicEntity>[]): { [id: string]: EntityOfType<DynamicEntity> } {
	const idMap = {};
	for (let i = 0; i < list.length; i++) {
		if (list[i]) {
			const id = list[i].Id;
			if (id && !idMap.hasOwnProperty(id)) {
				idMap[id] = list[i];
			}
		}
	}
	return idMap;
}

/**
 * Ensures that the given id is unique to the list,
 * and returns a unique pseudo-random id if it is not
 * @param list The list of items
 * @param item The item in the list who's Id is being validated
 * @param id The id to check for uniqueness
 * @param incorporateOriginal Whether to incorporate the original id when generating a unique id
 * @param idMap An optional pre-built map of ids to items
 * @returns The unique list item Id to use
 */
function ensureUniqueListItemId(list: EntityOfType<DynamicEntity>[], item: EntityOfType<DynamicEntity>, id: string, idMap: { [id: string]: EntityOfType<DynamicEntity> } = null): string {
	// Generate the id map on-demand if needed
	if (!idMap) {
		idMap = getListItemIdMap(list);
	}

	let itemForId = idMap[id];

	// If the Id isn't used or is used by the item being checked, then return it
	if (!itemForId || itemForId === item) {
		return id;
	}

	// Otherwise, fall back to a pseudo-random string
	for (let attempts = 0; attempts < 10; attempts++) {
		id = 'U-' + randomText(5 + attempts, true);
		itemForId = idMap[id];
		if (!itemForId) {
			return id;
		}
	}

	// Don't assign an id rather than knowingly use a duplicate
	return null;
}

/**
 * Get the form entry for the given entity (either a descendant of the form entry or the form entry itself)
 * @param entity Either a descendant of the form entry, or the form entry itself
 */
export function getFormEntry(entity: Entity): EntityOfType<FormEntry> {
	if (!entity)
		throw new Error('Can\'t get form entry for a null entity.');
	if (isFormEntry(entity))
		return entity;
	if (isSectionOrTable(entity))
		return entity.Form;
	throw new Error(`Can't get form entry for entity '${entity.meta.type.fullName}|${entity.meta.id || '?'}'.`);
}

/**
 * Initializes common properties of child entities: `Form`, `ParentSection`, and `Id`
 * @param parent The parent object that owns the child entity or entities
 * @param propertyName The property of the parent that contains the child entity or entities
 */
export function ensureChildProperties(parent: EntityOfType<FormEntry | DynamicEntity>, propertyName: string): void {
	const value = parent.get(propertyName);
	if (Array.isArray(value)) {
		value.forEach(item => setBackReferenceProperties(parent, item));
	}
	else if (value) {
		setBackReferenceProperties(parent, value);
	}

	if (Array.isArray(value)) {
		// Attempt to get the form entry from the parent object
		let formEntry: EntityOfType<FormEntry>;
		try {
			formEntry = getFormEntry(parent);
		}
		catch {
			// TODO: Log error to App Insights?
		}

		// Build a map of Ids once so that the list doesn't have to be traversed repeatedly to ensure unique Ids
		// NOTE: Store the id map on the list so that the id for an item that is deleted doesn't get reused, since it could have been referenced
		const idMap: { [id: string]: EntityOfType<DynamicEntity> } = value['__idMap'] || (value['__idMap'] = getListItemIdMap(value));

		// TODO: Make this more efficient by only processing added items?
		value.forEach((item, index) => {
			// Only generate Ids for new objects, or objects that don't already have an id
			if (item.meta.isNew || !item.Id) {
				let id: string;
				if (formEntry) {
					// Generate an encoded id based on the entry version and the items index in the list
					try {
						const currentEntryVersion = formEntry.Entry ? (formEntry.Entry.Version || 0) : 0;
						let attempts = 0;
						do {
							id = generateListItemId(currentEntryVersion + 1, index);
						} while (idMap[id] && ++attempts <= 10);
					}
					catch (e) {
						// If an error occurs, fall back to a pseudo-random string
						// TODO: Log error to App Insights?
						id = 'E-' + randomText(5, true);
					}
				}
				else {
					// Fall back to generate a random id
					id = 'F-' + randomText(5, true);
				}
				// Ensure that the list item's Id will be unique, generating a psuedo random Id if necessary
				id = ensureUniqueListItemId(value, item, id, idMap);
				// Assign the item's Id property
				item.Id = id;
				// Update the id map for future use
				idMap[id] = item;
			}
		});
	}
}

export function getInitialSectionItems(container: Entity, itemType: Type, count: number, itemInitializer: ObjectLookup<string>) {
	const items = [];
	if (container.meta.isNew) {
		for (let i = 0; i < count; i++)
			items.push(Object.assign({}, itemInitializer));
	}
	return items;
}

type EnumReferenceType = {
	// TODO: Is this type definition correct (Id: number)?
	Id: number;
	Name: string;
	DisplayName: string;
};

/**
 * Ensures that enum instances are created for the given enum type
 */
function ensureEnumInstances(type: Type, enumValues: ObjectLookup<string> | string[]): EntityOfType<EnumReferenceType>[] {
	const instances = type.known().reduce((obj, v) => { obj[v.meta.id] = v; return obj; }, {});
	const EnumType = type.jstype as EntityConstructorForType<EnumReferenceType>;
	if (Array.isArray(enumValues)) {
		return enumValues.map((name: string) => {
			return instances[name] || new EnumType(name, {
				Name: name,
				DisplayName: name
			});
		});
	}
	else {
		return Object.keys(enumValues).map((value: string) => {
			return instances[value] || new EnumType(value, {
				Id: parseInt(value, 10),
				Name: enumValues[value],
				DisplayName: enumValues[value]
			});
		});
	}
}

/**
 * Override of `Type.get` that ensure that enum values are created
 */
export function getEnumInstance(type: Type, ...args: any[]): Entity {
	const enumValues = type['enum'];
	if (!enumValues) {
		// TODO: Warn about an invalid call to `ensureEnumInstances`?
		return;
	}

	// Ensure that all enum instances are created
	ensureEnumInstances(type, enumValues);

	// Call the original Type.get prototype method
	const Type = type.constructor;
	return Type.prototype.get.apply(type, args);
}

export function getEnumInstanceById(type: Type, id: string) {
	const enumValues = type['enum'];
	if (!enumValues) {
		// TODO: Warn about an invalid call to `ensureEnumInstances`?
		return;
	}

	// Ensure that all enum instances are created
	const instances = ensureEnumInstances(type, enumValues);

	// Get the first instance with the matching id
	return instances.filter(i => i.Id === id)[0] || null;
}

export function getEnumInstanceByName(type: Type, name: string) {
	const enumValues = type['enum'];
	if (!enumValues) {
		// TODO: Warn about an invalid call to `ensureEnumInstances`?
		return;
	}

	// Ensure that all enum instances are created
	const instances = ensureEnumInstances(type, enumValues);

	// Get the first instance with the matching name
	return instances.filter(i => i.Name === name)[0] || null;
}
