import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter, IgnoreProperty } from '@cognitoforms/model.js';

/**
 * Prevents calculated properties from being serialized or deserialized.
 */
export default class CalculationConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.isCalculated;
	}

	serialize(): PropertySerializationResult {
		// no need to serialize calculated properties
		return IgnoreProperty;
	}

	deserialize() {
		// disallow deserializing calculated properties
		return IgnoreProperty;
	}
}