export function combineUInt16sWithPadding(n1: number, n2: number, randomByte1: number, randomByte2: number): number {
	if (n1 > 0xffff) {
		throw new Error('Value ' + n1 + ' exceeds maximum unsigned short value of ' + 0xffff + '.');
	}

	const n1Bytes = n1 > 0xff ? 2 : 1;

	if (n2 > 0xffff) {
		throw new Error('Value ' + n2 + ' exceeds maximum unsigned short value of ' + 0xffff + '.');
	}

	const n2Bytes = n2 > 0xff ? 2 : 1;

	if (n1Bytes > 1 && n2Bytes > 1) {
		throw new Error('Both values ' + n1 + ' and ' + n2 + ' exceeds maximum byte value of ' + 0xff + '.');
	}

	let result = 0;

	let randomByte: number;

	if (arguments.length > 2 && typeof randomByte1 === 'number') {
		randomByte = randomByte1;
	}
	else {
		randomByte = Math.floor(Math.random() * 255);
	}

	randomByte = randomByte & 0xff;

	// Bytes for n2
	result += n2;

	// Bytes for n1
	result += ((n1 << (n2Bytes === 2 ? 16 : 8))) >>> 0;

	if (n1Bytes === 1 && n2Bytes === 1) {
		// Random byte
		result += randomByte << 16;

		// Generate another random byte for the header
		if (arguments.length > 3 && typeof randomByte2 === 'number') {
			randomByte = randomByte2;
		}
		else {
			randomByte = Math.floor(Math.random() * 255);
		}

		randomByte = randomByte & 0xff;
	}

	// Header byte: n1 and n2 byte size, and random bits
	let headerByte = 0;
	headerByte += n2Bytes;
	headerByte += n1Bytes << 2;
	headerByte += (randomByte << 4) & 0x000000f0;
	result += (headerByte << 24) >>> 0;

	return result;
}

export function splitUInt32WithPadding(i: number): [number, number] {
	const headerByte = (i & 0xff000000) >>> 24;

	let n2: number;
	const n2Bytes = (headerByte & 0x07) & 0x3;
	if (n2Bytes === 2) {
		n2 = i & 0x0000ffff;
	}
	else {
		n2 = i & 0x000000ff;
	}

	let n1: number;
	const n1Bytes = ((headerByte & 0x0f) >> 2) & 0x3;
	if (n2Bytes === 2) {
		n1 = (i >>> 16) & 0xff;
	}
	else if (n1Bytes === 2) {
		n1 = (i >>> 8) & 0xffff;
	}
	else {
		n1 = (i >>> 8) & 0xff;
	}

	return [n1, n2];
}
