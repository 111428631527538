<template>
	<i-ex />
</template>

<script>
	import IEx from 'src/assets/ex.svg';

	export default {
		name: 'ToastMessageEx',
		components: { IEx }
	};
</script>