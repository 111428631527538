import type { Property, Entity } from '@cognitoforms/model.js';
import { PropertyConverter, IgnoreProperty } from '@cognitoforms/model.js';

/**
 * Prevents FileData properties from being deserialized into new entries.
 */
export default class IgnoreFileUploadConverter extends PropertyConverter {
	private entryTypeName: string;

	constructor(entryTypeName: string) {
		super();
		this.entryTypeName = entryTypeName;
	}

	shouldConvert(context: Entity, prop: Property): boolean {
		if (!context || prop.propertyType.name !== 'FileDataRef')
			return false;

		const entry = context.meta.type.fullName === this.entryTypeName ? context : context.Form;
		return entry && entry.meta.isNew;
	}

	deserialize(context: Entity, value: any, prop: Property) {
		return IgnoreProperty;
	}
}