import type { ObservableArray, Type, Entity, Property } from '@cognitoforms/model.js';
import { isEntityType } from '@cognitoforms/model.js';
import { FORM_ENTRY_TYPE_NAME } from 'src/framework/forms-model';

/**
 * Determine if the field is a lookup based off property name and type.
 * @param property
 * @returns boolean
 */
export function isLookupField(property: Property): boolean {
	const type = isEntityType(property.propertyType) && property.propertyType.meta;
	return property.name !== 'ParentSection' && property.name !== 'Form' && type && type.baseType && type.baseType.fullName === FORM_ENTRY_TYPE_NAME;
}

/**
 * Recursively visit all model properties of the provided entity.
 */
export function visitEntity(
	entity: Entity,
	callback: (entity: Entity, property: Property, parentProperty: Property | null) => void,
	options: { followCircularProperties: boolean, followLookups: boolean }
) {
	const visited = new Set();

	function visit(entity: Entity, parentProperty: Property = null) {
		if (entity === null || entity === undefined || visited.has(entity))
			return;

		visited.add(entity);

		for (const p of entity.meta.type.properties) {
			// Don't visit circular properties
			if (!options.followCircularProperties && (p.name === 'ParentSection' || (p.name === 'Form' && p.containingType.fullName !== FORM_ENTRY_TYPE_NAME)))
				continue;

			callback(entity, p, parentProperty);

			if (!options.followLookups && isLookupField(p))
				continue;

			if (isEntityType(p.propertyType)) {
				if (p.isList)
					(p.value(entity) as Entity[] || []).forEach(item => visit(item, p));
				else
					visit(p.value(entity), p);
			}
		}
	}

	visit(entity);
}

export function visitType(type: Type, callback: (type: Type, path: string) => void) {
	const visited = new Set();
	let path = '';
	function visit(type: Type) {
		if (type === null || type === undefined || visited.has(type))
			return;

		visited.add(type);
		callback(type, path);

		for (const p of type.properties) {
			if (isEntityType(p.propertyType)) {
				if (path.length)
					path += '.';

				path += p.name;
				visit(p.propertyType.meta);
				path = path.substring(0, path.lastIndexOf('.'));
			}
		}
	}
	visit(type);
}

/**
 * Remove the given entity from the model.js instance pool and known objects array
 */
export function unregisterEntity(instance: Entity): void {
	const key = instance.meta.id.toLowerCase();
	for (let t: Type = instance.meta.type; t; t = t.baseType) {
		// Remove the entity from the pool
		const pool = (t as any).__pool__ as { [id: string]: Entity };
		if (pool && key in pool) {
			delete pool[key];
		}

		// Remove the entity from the known entities list
		const known = (t as any).__known__ as ObservableArray<Entity>;
		if (known) {
			const indexOfEntity = known.indexOf(instance);
			if (indexOfEntity)
				known.splice(indexOfEntity, 1);
		}
	}
}
