import type { EntityOfType, Format, ObservableArray, Property } from '@cognitoforms/model.js';
import { updateArray } from '@cognitoforms/model.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import type { LookupEntryIndex } from './lookup-manager';
import type LookupManager from './lookup-manager';

export type CascadeFilterInfo = {
	lookupPropertyName: string;
	indexTypeName: string;
	indexPropertyName: string;
	priorFilters: { filterField: string, indexProperty: string }[];
}

@Component
export default class CascadeFilterManager extends Vue {
	@Prop()
	lookupManager: LookupManager;

	@Prop()
	cascadeProperty: Property;

	@Prop()
	indexProperty: Property;

	@Prop()
	priorFilters: { filterField: string, indexProperty: string }[];

	created() {
		this.lookupManager.$once('synthetic-indexes-changed', () => {
			// ensure synthetic index properties are initialized based on the initial value of the cascade property
			for (const index of this.lookupManager.syntheticIndexes) {
				const valueFromFilterField = this.isList ? this.listValue[0] : this.value;
				if (this.indexProperty.isList)
					this.indexProperty.value(index).push(valueFromFilterField);
				else
					this.indexProperty.value(index, valueFromFilterField);
			}
		});
	}

	get ready() {
		return this.lookupManager.ready;
	}

	get value() {
		return this.cascadeProperty.value(this.container) as any;
	}

	get listValue() {
		return this.cascadeProperty.value(this.container) as any[];
	}

	get isList() {
		return Array.isArray(this.value);
	}

	get container() {
		return this.lookupManager.container;
	}

	get indexes() {
		return this.lookupManager.indexes;
	}

	get format() {
		return this.cascadeProperty.format;
	}

	get filteredIndexes(): EntityOfType<LookupEntryIndex>[] {
		const prerequisites = Object.fromEntries(this.priorFilters.map(prior => {
			const prop = this.container.meta.type.getProperty(prior.filterField);
			const requiredFunc = typeof prop.required === 'function'
				? prop.required
				: typeof prop.required === 'object'
					? prop.required.function
					: null;
			return [
				prior.filterField,
				prop.required === true || (requiredFunc && requiredFunc.bind(this.container)())
			];
		}));

		if (this.indexProperty === null)
			return [];
		else
			return this.indexes
				.filter(idx => this.stringify(this.indexProperty.value(idx)) !== '')
				.filter(idx => this.priorFilters.every(prior => {
					const priorFormat = this.container.meta.type.getProperty(prior.filterField).format || null;
					const priorValue = this.container[prior.filterField];
					const indexValueFormatted = this.stringify(idx[prior.indexProperty], priorFormat);
					const indexValue = idx[prior.indexProperty];

					if (Array.isArray(indexValue) && Array.isArray(priorValue))
						return (!prerequisites[prior.filterField] && !priorValue.length) || priorValue.some(v => indexValue.includes(this.stringify(v, priorFormat)));
					else if (Array.isArray(indexValue))
						return (!prerequisites[prior.filterField] && priorValue === null) || indexValue.some(v => priorValue === this.stringify(v, priorFormat));
					else if (Array.isArray(priorValue))
						return (!prerequisites[prior.filterField] && !priorValue.length) || priorValue.some(v => indexValueFormatted === this.stringify(v, priorFormat));

					return (!prerequisites[prior.filterField] && priorValue === null) || indexValueFormatted === this.stringify(priorValue, priorFormat);
				}));
	}

	/**
	 * When the filtered options no longer include the currently selected value, clear the value.
	 * @param indexes The new set of filtered options.
	 */
	@Watch('filteredIndexes')
	filteredIndexesChanged(indexes: EntityOfType<LookupEntryIndex>[]) {
		const allowedStrings = indexes.flatMap(this.extract).map(val => this.stringify(val));
		const isAllowed = (val: any) => allowedStrings.includes(this.stringify(val));

		if (this.isList)
			this.setFieldValue(this.listValue.filter(isAllowed));
		else if (!isAllowed(this.value))
			this.setFieldValue(null);
	}

	setFieldValue(value: any | any[]) {
		if (this.isList) {
			const list = (this.listValue as ObservableArray<any>);
			list.batchUpdate(() => updateArray(list, value as any[]));
		}
		else
			this.cascadeProperty.value(this.container, value);
	}

	parse(value: string) {
		return this.format ? this.format.convertFromString(value) : value;
	}

	stringify(value: any, format: Format<any> = this.format) {
		if (value === null)
			return '';
		return format ? format.convertToString(value) : '' + value;
	}

	extract(index: EntityOfType<LookupEntryIndex>) {
		return this.indexProperty.value(index);
	}
}

export type CascadeFilterManagerOptions = {
	lookupManager: LookupManager;
	cascadeProperty: Property;
	indexProperty: Property;
	priorFilters: { filterField: string, indexProperty: string }[];
};

export type CascadeFilterManagerFactory = (options: CascadeFilterManagerOptions) => CascadeFilterManager;

export function getCascadeFilterManager({ lookupManager, cascadeProperty, indexProperty, priorFilters }: CascadeFilterManagerOptions) {
	return new CascadeFilterManager({
		propsData: {
			lookupManager,
			cascadeProperty,
			indexProperty,
			priorFilters
		}
	});
}