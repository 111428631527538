import type { Property, Entity, PropertySerializationResult } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';
import { getEnumInstanceById, getEnumInstanceByName } from '../../core';

/**
 * Handles serialization/deserialization of enum values
 */
export default class EnumConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		const propModelType = prop.propertyType && prop.propertyType['meta'];

		// Not a model type
		if (!propModelType)
			return false;

		// Convert if the model type is an enum
		return propModelType.enum != null;
	}

	serialize(context: Entity, value: any, prop: Property): PropertySerializationResult {
		if (value) {
			const propModelType = prop.propertyType && prop.propertyType['meta'];
			return {
				key: prop.name,
				value: Array.isArray(propModelType.enum) ? value.Name : value.Id
			};
		}
	}

	deserialize(context: Entity, value: any, prop: Property) {
		if (value) {
			if (typeof value === 'string') {
				return getEnumInstanceById(prop.propertyType['meta'], value) || getEnumInstanceByName(prop.propertyType['meta'], value);
			}
			else if (typeof value === 'object') {
				if (value.Id) {
					return getEnumInstanceById(prop.propertyType['meta'], value.Id);
				}
				else if (value.Name) {
					return getEnumInstanceByName(prop.propertyType['meta'], value.Name);
				}
			}
		}

		return value;
	}
}
