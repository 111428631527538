import type { Entity, Property } from '@cognitoforms/model.js';
import { isEntityType } from '@cognitoforms/model.js';
import type { FormsModel } from 'src/framework/forms-model';

function ensurePropertyInitialized(property: Property, entity: Entity) {
	if (!property.value(entity))
		entity.update(property.name, {});
}

function isDynamicEntity(property: Property) {
	return isEntityType(property.propertyType)
		&& property.propertyType.meta.baseType
		&& property.propertyType.meta.baseType.fullName === 'DynamicEntity';
}

function isName(property: Property) {
	return isEntityType(property.propertyType)
		&& property.propertyType.meta.fullName === 'Name';
}

function isAddress(property: Property) {
	return isEntityType(property.propertyType)
		&& property.propertyType.meta.fullName === 'Address';
}

/**
 * Ensures properties of type Name, Address, and DynamicEntity subtypes are initialized for existing entities.
 * These properties are initialized using default value rules for new entities.
 */
export function applyEntityInitExtensions(model: FormsModel) {
	for (const type of Object.values(model.model.types)) {
		const referenceProperties = type.properties.filter(p => !p.isList && (isDynamicEntity(p) || isName(p) || isAddress(p)));
		if (referenceProperties.length)
			type.initExisting.subscribe(e => referenceProperties.forEach(property => ensurePropertyInitialized(property, e.entity)));
	}
}