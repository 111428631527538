import type { Property, Entity, AsyncValueResolver, Type } from '@cognitoforms/model.js';
import { Property$pendingInit, isEntityType } from '@cognitoforms/model.js';
import type { FormsModel, FormsModelOptions } from 'src/framework/forms-model';
import type { ObjectLookup } from 'src/util';
import type EntryViewService from 'src/web-api/entry-views/entry-view-service';
import type { CascadeFilterInfo, CascadeFilterManagerFactory } from './cascade-filter-manager';
import CascadeFilterManager from './cascade-filter-manager';
import type { LookupIndexInfo, LookupManagerFactory } from './lookup-manager';
import LookupManager from './lookup-manager';
import { getFormEntry } from '../../core';

function isRootLookupProperty(propDef: any) {
	return typeof propDef === 'object' && propDef['$lookupFieldInfo'];
}

function isNestedLookupProperty(propDef: any) {
	return typeof propDef === 'object' && propDef['$lookupViewId'];
}

function isCascadeFilterProperty(propDef: any) {
	return typeof propDef === 'object' && propDef['$cascadeFilterInfo'];
}

function getManagerPropName(lookupProperty: Property) {
	return `${lookupProperty.name}_LookupManager`;
}

/**
 * Adds a rule to the specified type which ensures a lookup manager's filtered indexes are
 * kept up to date as dependent model properties change.
 */
function addLookupFilterRules(type: Type, lookupProperty: Property, lookupInfo: LookupIndexInfo): void {
	// Don't allow a lookup to be prefilled with invalid values
	lookupProperty.changed.subscribe(function () {
		const entry = getFormEntry(this);
		if (entry.meta.isNew) {
			const manager = this[getManagerPropName(lookupProperty)] as LookupManager;
			if (manager)
				manager.whenReady(() => manager.clearInvalidValues());
		}
	});

	// Ensure that lookup value(s) is/are valid when a filter predicate changes
	type.addRule({
		name: `${lookupProperty.containingType.fullName}.${lookupProperty.name}.UpdateFilteredIndexes`,
		execute() {
			const manager = this[getManagerPropName(lookupProperty)] as LookupManager;
			if (manager)
				manager.whenReady(() => manager.updateFilteredIndexes(true));
		},
		onChangeOf: type.getPaths(`{${lookupInfo.filterPaths.join(',')}}`)
	}).register();
}

/**
 * Adds rules to the specified type which ensures a lookup manager's default indexes are
 * established and default filter is applied initially, and as as dependent model properties change.
 */
function addLookupDefaultRules(type: Type, lookupProperty: Property, lookupInfo: LookupIndexInfo): void {
	// Apply the default filter when the entry is initialized
	// - for new entries if the value has not been prefilled
	// - for existing entries when there is no persisted value
	type.addRule({
		name: `${lookupProperty.containingType.fullName}.${lookupProperty.name}.SetInitialDefaultValue`,
		execute() {
			const manager = this[getManagerPropName(lookupProperty)] as LookupManager;
			if (manager) {
				manager.whenReady(() => {
					// Wait for the entry to initialize to ensure that prefilled or persisted lookup values have been resolved
					getFormEntry(this).initialized.then(() => {
						const isInited = lookupProperty.isInited(this) && !Property$pendingInit(this, lookupProperty);
						if (!isInited)
							manager.updateDefaultIndexes(true);
					});
				});
			}
		}
	}).onInit().register();

	// Apply the default filter whenever one of the default filter predicates changes
	type.addRule({
		name: `${lookupProperty.containingType.fullName}.${lookupProperty.name}.UpdateDefaultIndexesAndSetDefaultValue`,
		execute() {
			const manager = this[getManagerPropName(lookupProperty)] as LookupManager;
			if (manager)
				manager.whenReady(() => manager.updateDefaultIndexes(true));
		},
		onChangeOf: type.getPaths(`{${lookupInfo.defaultPaths.join(',')}}`)
	}).register();

	// Ensure that a default filter rule will run if a calculation that it depends on is changed.
	// A property with a default value rule may have a persisted value, in which case it will not run unless one of its predicates
	// fires a change event. A calculation will not fire a change event the first time it runs if it didn't previously have a value,
	// which may be the case for existing instances if the calculation is never accessed (ex: a hidden field), or is not accessed
	// prior to the event that would trigger it to change (i.e. setting the default action). So, in order to ensure that the default
	// filter's calculated predicates fire a change event, we must ensure that the calculation is accessed when the object is initialized.
	type.addRule({
		name: `${lookupProperty.containingType.fullName}.${lookupProperty.name}.AccessDefaultValuePredicates`,
		execute() {
			type.getPaths(`{${lookupInfo.defaultPaths.join(',')}}`).forEach(p => p.value(this));
		}
	}).onInitExisting().register();
}

export function extendModelWithLookups(
	model: FormsModel,
	options: FormsModelOptions,
	entryViewService: EntryViewService,
	getLookupManager: LookupManagerFactory,
	getCascadeFilterManager: CascadeFilterManagerFactory,
	pseudoLoadDeleted: boolean
): AsyncValueResolver {
	/**
	 * Adds the LookupManger property to the lookup manager's containingType.
	 */
	function addLookupManagerProperty(lookupProperty: Property, lookupInfo: LookupIndexInfo, entryViewService: EntryViewService, getLookupManager: LookupManagerFactory) {
		lookupProperty.containingType.extend({
			[getManagerPropName(lookupProperty)]: {
				get: function(this: Entity) {
					return getLookupManager({
						lookupInfo,
						indexType: model.resolveType(lookupInfo.indexTypeName),
						entryViewService,
						lookupProperty,
						container: this
					});
				},
				type: LookupManager as any
			}
		});

		lookupProperty.containingType.addRule(function autoCreateLookupManager(this: Entity) {
			// force the manager to be created by accessing the property
			this.get(getManagerPropName(lookupProperty));
		}).onInit().register();
	}

	/**
	 * Adds the CascadeFilterManager property to the cascade filter manager's containingType.
	 */
	function addCascadeFilterManagerProperty(cascadeProperty: Property, filterInfo: CascadeFilterInfo, getCascadeFilterManager: CascadeFilterManagerFactory) {
		const cascadeManagerProp = `${cascadeProperty.name}_CascadeFilterManager`;
		cascadeProperty.containingType.extend({
			[cascadeManagerProp]: {
				get: {
					function() {
						return getCascadeFilterManager({
							lookupManager: this[`${filterInfo.lookupPropertyName}_LookupManager`],
							cascadeProperty,
							indexProperty: model.resolveType(filterInfo.indexTypeName).getProperty(filterInfo.indexPropertyName),
							priorFilters: filterInfo.priorFilters
						});
					},
					dependsOn: `${filterInfo.lookupPropertyName}_LookupManager`
				},
				type: CascadeFilterManager as any
			}
		});

		cascadeProperty.containingType.addRule(function autoCreateCascadeManager() {
			// force the manager to be created by accessing the property
			this.get(cascadeManagerProp);
		}).onInit().register();
	}

	const lookupViews = new Map<Property, string>();

	/**
	 * Traverse the model definition and do the following:
	 * - harvest lookup view ids for all lookup fields
	 * - create rules to update filtered/default options for lookup fields
	 * - create rules to initialize lookup/cascade filter managers for any type containing a lookup field
	 */
	for (const [typeName, typeDef] of Object.entries(options)) {
		if (!typeName.startsWith('$')) {
			const currentType = model.resolveType(typeName);
			const cascadeManagersToInitialize = [];
			for (const [propName, propDef] of Object.entries(typeDef)) {
				const currentProperty = currentType.getProperty(propName);

				// lookups on secondary forms (not the root) only need the viewId to load entries
				if (isNestedLookupProperty(propDef))
					lookupViews.set(currentProperty, propDef['$lookupViewId']);
				else if (isCascadeFilterProperty(propDef)) {
					if (!options.$disableLookupFiltering)
						cascadeManagersToInitialize.push(() => addCascadeFilterManagerProperty(currentProperty, propDef['$cascadeFilterInfo'] as CascadeFilterInfo, getCascadeFilterManager));
				}
				// lookups on the root form need more metadata to describe the index type for displaying options
				else if (isRootLookupProperty(propDef)) {
					const lookupInfo = propDef['$lookupFieldInfo'] as LookupIndexInfo;

					lookupViews.set(currentProperty, lookupInfo.viewId);

					if (!options.$disableLookupFiltering) {
						addLookupManagerProperty(currentProperty, lookupInfo, entryViewService, getLookupManager);

						if (lookupInfo.filterPaths)
							addLookupFilterRules(currentType, currentProperty, lookupInfo);

						if (lookupInfo.defaultPaths)
							addLookupDefaultRules(currentType, currentProperty, lookupInfo);
					}
				}
			}

			if (cascadeManagersToInitialize.length)
				cascadeManagersToInitialize.forEach(addCascadeFilterManager => addCascadeFilterManager());
		}
	}

	/**
	 * Attempts to load form entries for lookup properties with a corresponding lookup view using the configured EntryViewService.
	 */
	function lookupEntryResolver(instance: Entity, property: Property, value: any): Promise<Entity | Entity[]> | null {
		const viewId = lookupViews.get(property);
		const isList = Array.isArray(value);
		if (viewId && value && isEntityType(property.propertyType)) {
			const ids = isList ? value as any[] : [value];
			const loaded: ObjectLookup<Entity> = {};

			// determine which entries are already in memory
			for (const id of ids) {
				const entry = property.propertyType.meta.get(id);
				if (entry)
					loaded[id] = entry;
			}

			const unloadedIds = ids.filter(id => !loaded[id]);
			let dataLoaded: Promise<any> = Promise.resolve();

			// load remote entry data if necessary
			if (unloadedIds.length)
				dataLoaded = entryViewService.lookupEntries(viewId, unloadedIds)
					.then(entryData => entryData.forEach(data => (loaded[data.Id] = data)))
					.catch(reason => console.warn('Unable to resolve lookup value:', value, reason));

			// return all entries in order based on provided value
			if (pseudoLoadDeleted) {
				return dataLoaded.then(() => {
					for (const unloaded of unloadedIds) {
						if (!loaded[unloaded])
							loaded[unloaded] = { Id: 'Deleted|' + unloaded };
					}
					return ids.map(id => loaded[id]);
				}).then(entries => isList ? entries : (entries[0] !== undefined ? entries[0] : null));
			}
			else {
				return dataLoaded
					.then(() => ids.map(id => loaded[id]))
					.then(entries => isList ? entries.filter(entry => entry !== undefined) : (entries[0] !== undefined ? entries[0] : null));
			}
		}

		// return null so the framework knows there is no async resolution for this value
		else
			return null;
	}

	return lookupEntryResolver;
}
