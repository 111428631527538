import { isEntityType, type Entity } from '@cognitoforms/model.js';
import type { EntryViewFilter } from '@cognitoforms/types/server-types/forms/model/entry-view-filter';
import type { CognitoGlobals } from './entry-view-types';

// temporary type declaration for accessing entry statuses, see getStatusFunction
declare let Cognito: CognitoGlobals;

// Compile a Javascript function from a custom filter expression
export function compileExpression(fn: {Exports: object, Body: string}, Cognito: object): any {
	let expression = '';
	for (const exportFn in fn.Exports) {
		if (Object.prototype.hasOwnProperty.call(fn.Exports, exportFn)) {
			expression += `const ${exportFn}=${fn.Exports[exportFn]};`;
		}
	}
	if (expression) {
		expression += `return function() { return ${fn.Body}; }`;
		// eslint-disable-next-line no-new-func
		const compile = Function('Cognito', expression);
		return compile(Cognito);
	}
	else {
		// eslint-disable-next-line no-new-func
		return Function(`return ${fn.Body};`);
	}
}

// Determines whether the entity contains the specified keyword
function matchesKeyword(entity: Entity, keyword: string, visited: Set<Entity>): boolean {
	// If entity is null or is an instance of EntryMeta, break
	if (entity == null || entity.meta.type.baseType?.fullName === 'Forms.EntryMeta') return false;

	// Push the entity onto the stack to prevent recursive visitation of the same entity
	visited.add(entity);

	// Search all instance properties of the entity
	const properties = entity.meta.type.properties;
	for (const property of properties) {
		// If property produces value of type FormRef/FormEntry or is an internal property, break
		if (property.name === 'Form' || property.name === 'ParentSection' || property.name.indexOf('_') > 0)
			continue;

		const value = property.value(entity);
		if (value != null) {
			// Perform a case-insensitive search of value properties
			if (!isEntityType(property.propertyType)) {
				if (value.toString().toLowerCase().indexOf(keyword) > -1)
					return true;
			}

			// Recursively search child entities and entity lists
			else {
				if (value instanceof Array) {
					for (let i = 0; i < value.length; i++) {
						if (!visited.has(value[i]) && matchesKeyword(value[i], keyword, visited))
							return true;
					}
				}
				else if (!visited.has(value) && matchesKeyword(value, keyword, visited))
					return true;
			}
		}
	}
	return false;
}

export function getKeywordFunction(filter: EntryViewFilter): () => boolean {
	let keywordFn: () => boolean;
	if (filter.Keyword) {
		const keywordRegex = /"([\w\s]*?)"|(\w+)/g;
		const keywords = [];
		let keyword: string[];
		while ((keyword = keywordRegex.exec(filter.Keyword)) !== null)
			keywords.push((keyword[1] || keyword[2]).toLowerCase());

		keywordFn = function (this: Entity) {
			for (let k = 0; k < keywords.length; k++)
				if (!matchesKeyword(this, keywords[k], new Set()))
					return false;
			return true;
		};
	}
	return keywordFn;
}

export function getStatusFunction(filter: EntryViewFilter, entryType: string): () => boolean {
	let statusFn: () => boolean;
	if (filter.EntryStatus.length > 0 || filter.PaymentStatus.length > 0) {
		let entryStatus = filter.EntryStatus.length > 0 ? filter.EntryStatus : null;
		const paymentStatus = filter.PaymentStatus.length > 0 ? filter.PaymentStatus : null;
		// If an entry status filter is present, map status id filters to status names if necessary
		if (entryStatus && Cognito.Forms.EntryStatuses?.[entryType]?.length) {
			const entryStatuses = Cognito.Forms.EntryStatuses[entryType];

			entryStatus = entryStatus.map(function (statusId) {
				if (isNaN(statusId))
					return statusId;

				return Cognito.Forms.getEntryStatusById(entryStatuses, statusId).Name;
			});
		}

		statusFn = function (this: Entity) {
			const form = this['ItemNumber'] ? this['Form'] : this;
			return (!entryStatus || entryStatus.indexOf(form.Entry.Status) > -1) && (!paymentStatus || (form.Order?.PaymentStatus && paymentStatus.indexOf(form.Order.PaymentStatus.Name) > -1));
		};
	}
	return statusFn;
}
