import type { Property, PropertySerializationResult, Entity, EntityOfType } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import { isLookupField } from 'src/util/model';

/**
 * Prevents calculated properties from being serialized or deserialized.
 */
export default class LookupConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return isLookupField(prop);
	}

	serialize(context: Entity, value: any, property: Property): PropertySerializationResult {
		if (Array.isArray(value)) {
			const lookupValue = value as EntityOfType<FormEntry>[];
			return { key: property.name, value: lookupValue.map(e => e.Id) };
		}
		else if (value) {
			const lookupValue = value as EntityOfType<FormEntry>;
			return { key: property.name, value: lookupValue.Id };
		}
		return { key: property.name, value: null };
	}

	deserialize(context: Entity, value: any, property: Property): any {
		return value;
	}
}