import { Entity, isEntityType, PropertyConverter } from '@cognitoforms/model.js';
import type { Property } from '@cognitoforms/model.js';
import type { FormEntryStatus } from '@cognitoforms/types/server-types/forms/model/form-entry-status';

function getActiveEntryStatus(statuses: FormEntryStatus[], statusIdOrName) {
	let newStatus = statuses.filter(s => !s.IsArchived).find(s => s.Id === Number(statusIdOrName) || s.Name === statusIdOrName);
	if (!newStatus)
		newStatus = statuses.find(s => s.Id === Number(statusIdOrName) || s.Name === statusIdOrName);
	if (newStatus == null || newStatus.ReplacementId == null)
		return newStatus;
	else
		return getActiveEntryStatus(statuses, newStatus.ReplacementId);
}

const INCOMPLETE_STATUS_ID = 0;

// If the status list is missing the entry's status for some reason, pick either the incomplete status, or the first non-incomplete status
// based on whether or not the entry is incomplete.
function getDefaultStatus(statuses: FormEntryStatus[], isEntryIncomplete: boolean): any {
	return statuses.find(s => isEntryIncomplete ? s.Id === INCOMPLETE_STATUS_ID : s.Id !== INCOMPLETE_STATUS_ID && !s.IsArchived);
}

/**
 * Handles deserialization of entry status ids into status names.
 */
export default class EntryMetaConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.name === 'Entry'
			&& isEntityType(prop.propertyType)
			&& (prop.propertyType.meta.baseType || prop.propertyType.meta).fullName === 'Forms.EntryMeta';
	}

	deserialize(context: Entity, value: any, prop: Property) {
		const entryMeta = value;
		const statuses = context['Entry_Statuses'];
		// if the EntryMeta object is already an entity instance, its status will already be correct
		if (statuses && entryMeta && !(entryMeta instanceof Entity)) {
			const isEntryIncomplete = !entryMeta.DateSubmitted;

			let status = null;
			if (entryMeta.Status !== null && entryMeta.Status !== undefined)
				status = getActiveEntryStatus(statuses, entryMeta.Status);

			status = status || getDefaultStatus(statuses, isEntryIncomplete);

			entryMeta.Status = status.Name;
		}

		return super.deserialize(context, value, prop);
	}
}