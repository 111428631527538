export class CustomResponseError extends Error {
	type: string;
	data: any;
	status: number;
	statusText: string;
	constructor(errorInfo: JsonErrorInfo, status: number, statusText: string) {
		super(errorInfo.Message);
		this.type = errorInfo.Type;
		this.data = errorInfo.Data;
		this.status = status;
		this.statusText = statusText;
	}
}

export type JsonErrorInfo = {
	Type?: string;
	Message?: string;
	Data?: any;
}