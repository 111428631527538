import type { Delta } from 'jsondiffpatch';
import { Pipe } from 'jsondiffpatch';

const compare = {
	numerically(a, b) {
		return a - b;
	},
	numericallyBy(name) {
		return (a, b) => a[name] - b[name];
	}
};

// https://github.com/benjamine/jsondiffpatch/blob/master/src/contexts/context.js
// Added properties for intelisense
class Context {
	result: any;
	hasResult: boolean;
	exiting: boolean;
	next: any;
	nextPipe: any;
	root: any;
	children: any;
	nextAfterChildren: any;
	options: any;
	setResult(result) {
		this.result = result;
		this.hasResult = true;
		return this;
	}

	exit() {
		this.exiting = true;
		return this;
	}

	switchTo(next, pipe) {
		if (typeof next === 'string' || next instanceof Pipe) {
			this.nextPipe = next;
		}
		else {
			this.next = next;
			if (pipe) {
				this.nextPipe = pipe;
			}
		}
		return this;
	}

	push(child, name) {
		child.parent = this;
		if (typeof name !== 'undefined') {
			child.childName = name;
		}
		child.root = this.root || this;
		child.options = child.options || this.options;
		if (!this.children) {
			this.children = [child];
			this.nextAfterChildren = this.next || null;
			this.next = child;
		}
		else {
			this.children[this.children.length - 1].next = child;
			this.children.push(child);
		}
		child.next = this;
		return this;
	}
}

// https://github.com/benjamine/jsondiffpatch/blob/master/src/contexts/patch.js
// Added properties for intelisense
class PatchContext extends Context {
	left;
	delta: Delta;
	pipe: string;
	constructor(left, delta) {
		super();
		this.left = left;
		this.delta = delta;
		this.pipe = 'patch';
	}
}

// https://github.com/benjamine/jsondiffpatch/blob/master/src/filters/nested.js
// collectChildrenDiffFilter()
// Add ids to the delta if the object contains an Id
export const addIdsToRepeatingSectionObjects = (context) => {
	if (!context || !context.children) {
		return;
	}
	const length = context.children.length;
	let child;
	let result = context.result;
	for (let index = 0; index < length; index++) {
		child = context.children[index];
		if (typeof child.result === 'undefined') {
			continue;
		}
		result = result || {};
		result[child.childName] = child.result;
	}
	if (result && context.leftIsArray) {
		result._t = 'a';
	}
	context.setResult(result).exit();

	// Start Cognito-specific patching logic for repeating sections
	// Add ids to the delta if the object contains an Id
	if (context.hasResult && context.result && (context.right.Id || context.left.Id) && !(context.right.Id || context.left.Id).includes('-')) {
		context.result.$id = context.right.Id || context.left.Id;
	}
	// End Cognito-specific patching logic for repeating sections
};
addIdsToRepeatingSectionObjects.filterName = 'addIdsToRepeatingSectionObjects';

// https://github.com/benjamine/jsondiffpatch/blob/master/src/filters/arrays.js
// nestedPatchFilter()
export const arrayFilterPreventModificationsFromMissingItems = (context) => {
	if (!context.nested) {
		return;
	}
	if (context.delta._t !== 'a') {
		return;
	}
	let index;

	const ARRAY_MOVE = 3;
	const delta = context.delta;
	const array = context.left;

	// first, separate removals, insertions and modifications
	let toRemove = [];
	let toInsert = [];
	const toModify = [];
	for (index in delta) {
		if (index !== '_t') {
			if (index[0] === '_') {
				// removed item from original array
				if (delta[index][2] === 0 || delta[index][2] === ARRAY_MOVE) {
					toRemove.push(parseInt(index.slice(1), 10));
				}
				else {
					throw new Error(
						'only removal or move can be applied at original array indices,' +
                            ` invalid diff type: ${delta[index][2]}`
					);
				}
			}
			else {
				if (delta[index].length === 1) {
					// added item at new array
					toInsert.push({
						index: parseInt(index, 10),
						value: delta[index][0]
					});
				}
				else {
					// modified item at new array
					toModify.push({
						index: parseInt(index, 10),
						delta: delta[index]
					});
				}
			}
		}
	}

	// remove items, in reverse order to avoid sawing our own floor
	toRemove = toRemove.sort(compare.numerically);
	for (index = toRemove.length - 1; index >= 0; index--) {
		const indexDiff = delta[`_${toRemove[index]}`];
		const itemIndex = array.findIndex(item => context.options.objectHash(indexDiff[0]) === context.options.objectHash(item));
		if (itemIndex !== -1) {
			array.splice(itemIndex, 1);

			if (indexDiff && indexDiff[2] === ARRAY_MOVE) {
				// reinsert later
				toInsert.push({
					index: array.length + toInsert.length,
					value: indexDiff[0]
				});
			}
		}
	}

	// insert items, in reverse order to avoid moving our own floor
	toInsert = toInsert.sort(compare.numericallyBy('index'));
	const toInsertLength = toInsert.length;
	for (index = 0; index < toInsertLength; index++) {
		const insertion = toInsert[index];
		array.splice(insertion.index, 0, insertion.value);
	}

	// apply modifications
	const toModifyLength = toModify.length;
	let child;
	if (toModifyLength > 0) {
		for (index = 0; index < toModifyLength; index++) {
			const modification = toModify[index];
			// Start Cognito-specific patching logic for repeating sections
			if (modification.delta.$id != null) {
				// Prevent modifications to objects that are not in the array
				const leftSide = context.left.find((left) => left.Id === modification.delta.$id);
				if (leftSide) {
					delete modification.delta.$id;
					child = new PatchContext(
						leftSide,
						modification.delta
					);
					context.push(child, modification.index);
				}
			}
			// End Cognito-specific patching logic for repeating sections
			else {
				child = new PatchContext(
					context.left[modification.index],
					modification.delta
				);
				context.push(child, modification.index);
			}
		}
	}

	if (!context.children) {
		context.setResult(context.left).exit();
		return;
	}
	context.exit();
};
arrayFilterPreventModificationsFromMissingItems.filterName = 'arrayFilterPreventModificationsFromMissingItems';

// https://github.com/benjamine/jsondiffpatch/blob/master/src/filters/arrays.js
// collectChildrenPatchFilter()
export const arraysCollectPreventDuplicateCollections = (context) => {
	if (!context || !context.children) {
		return;
	}
	if (context.delta._t !== 'a') {
		return;
	}

	const length = context.children.length;
	for (let index = 0; index < length; index++) {
		const child = context.children[index];
		// Start Cognito-specific patching logic for repeating sections
		// Prevent adding results that are already in the array
		if (context.left.map(left => left.Id).indexOf(child.result.Id) === -1)
			context.left[child.childName] = child.result;
		// End Cognito-specific patching logic for repeating sections
	}
	context.setResult(context.left).exit();
};
arraysCollectPreventDuplicateCollections.filterName = 'arraysCollectPreventDuplicateCollections';
