import { ServiceWithSession } from './base-service';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { QuantityData } from './quantity-service';
import type { Entity, EntityOfType, Property } from '@cognitoforms/model.js';
import { isLookupField } from 'src/util/model';
import type { FormEntryExtensions } from 'src/framework/model/extensions/form-entry-extensions';

// Regex for an entry token
const entryTokenRegex = /#?(?<entryToken>.{44}[*!])/;

export function tryParseEntryToken(token: string): { success: boolean; entryToken?: string; } {
	token = decodeURIComponent(token);
	const match = token.match(entryTokenRegex);
	if (match)
		return {
			success: true,
			entryToken: match.groups.entryToken
		};
	else
		return { success: false };
}

type BaseEntryStoreResult = {
	status: SubmissionResultStatus,
	entry?: any,
	order?: any,
	message?: string,
	data?: any
}

export type EntryDocument = {
	link: string;
	title: string;
	type: string;
}

export type EntrySubmissionResult = BaseEntryStoreResult & {
	documents?: EntryDocument[],
	entryToken?: string,
	auditRecordId?: string
}

export type EntrySaveResult = BaseEntryStoreResult & {
	emailAddress: string;
	emailMessage: string;
	link: string;
	entryToken: string;
	enableSend: boolean;
}

export enum ResumeMode {
	View = 'View',
	Edit = 'Edit'
}

export enum SubmissionResultStatus {
	Success = 'Success',
	AlreadyPaid = 'AlreadyPaid',
	QuantityLimitExceeded = 'QuantityLimitExceeded',
	PaymentDeclined = 'PaymentDeclined',
	CardDeclined = 'CardDeclined',
	OrderMismatch = 'OrderMismatch',
	AlreadySubmitted = 'AlreadySubmitted',
	Unknown = 'Unknown',
	Error = 'Error'
}

export type ResumeEntryResult = {
	entry: any;
	order?: any;
	mode: ResumeMode;
	lastPageViewed?: string;
	userEmail?: string;
	role?: string;
}

export abstract class EntryService extends ServiceWithSession {
	abstract submit(entry: EntityOfType<FormEntry>, embedUrl: string, entryToken: string): Promise<EntrySubmissionResult>;

	abstract save(entry: EntityOfType<FormEntry>, embedUrl: string, entryToken: string, resumePage?: number): Promise<EntrySaveResult>;

	abstract emailResumeLink(entryId: string, recipient: string, message: string, link: string): void;

	abstract resume(formId: string, entryToken: string): Promise<ResumeEntryResult>;
};

function generateReverseMapping(entry: EntityOfType<FormEntry & FormEntryExtensions>) {
	if (!entry.Page_Index)
		return { 1: entry.meta.type.properties.map(p => p.name) };

	const reverseMap = {};
	for (const field of Object.keys(entry.Page_Index)) {
		const pageNum = entry.Page_Index[field];

		if (!reverseMap[pageNum])
			reverseMap[pageNum] = [];

		reverseMap[pageNum].push(field);
	}

	return reverseMap;
}

function validateProperty(entity: Entity, prop: Property, parent: Entity, parentHidden: boolean) {
	// If this isn't an entity type, return
	if (!entity || !entity.meta || !entity.meta.conditions)
		return true;

	// Only validate fields that are visible on entry
	const isFieldShown = (property: any) => {
		if (entity[property.name] === undefined)
			return false;

		// Skip over fields on hidden pages
		if (!parent && (entity as EntityOfType<FormEntry & FormEntryExtensions>).Page_Index) {
			const pageNumber = (entity as EntityOfType<FormEntry & FormEntryExtensions>).Page_Index[property.name];
			const pageVisibleProp = `Page${pageNumber}Visible`;
			if (entity[pageVisibleProp] === false)
				return false;
		}

		// Skip fields that are hidden
		if (entity[property.name + '_Visible'] === false)
			return false;

		// Skip payment since suppressed here
		if (property.name === 'Save_Card_Agreement')
			return false;

		if (parentHidden)
			return false;

		return true;
	};

	// If the specified property has a condition that isn't met, return false
	const conditions = entity.meta.conditions;
	const relevantConditions = conditions.filter(condition => {
		return condition.properties.find(p => p.name === prop.name);
	});
	if (isFieldShown(prop)) {
		if (relevantConditions.some(c => c.properties.some(p => p.name === prop.name)))
			return false;
	}
	// Need to always validate hidden quantity limits
	else if (entity[prop.name + '_QuantityLimit'] !== undefined) {
		if (relevantConditions.some(c => c.condition.type.code.endsWith('.QuantityValidation')))
			return false;
	}

	// Recursively check sections and repeating sections for unmet conditions
	// Avoid recursively validating invisible children, lookups, and 'entity.Form'
	if (!isLookupField(prop) && prop.name !== 'Form') {
		const child = entity[prop.name];

		// Rating scales reference their parent section, skip this property
		if (child === parent)
			return true;

		const fieldShown = isFieldShown(prop);

		// Recursively check each element in repeating sections and tables
		if (prop.isList) {
			if (!Array.isArray(child))
				return true;

			for (const item of child) {
				if (!item || !item.meta)
					return true;

				for (const p of item.meta.type.properties) {
					if (!validateProperty(item, p, entity, !fieldShown))
						return false;
				}
			}
		}
		// Recursively check sections
		else if (typeof child === 'object') {
			if (!child || !child.meta)
				return true;

			for (const p of child.meta.type.properties) {
				if (!validateProperty(child, p, entity, !fieldShown))
					return false;
			}
		}
	}

	return true;
}

export function validateByPage(entry: EntityOfType<FormEntry & FormEntryExtensions>, startingPage?: number, endingPage?: number) {
	const reverseMap = generateReverseMapping(entry);
	const maxPage = Math.max.apply(undefined, Object.keys(reverseMap));
	for (let i = 1; i < maxPage + 1; i++) {
		// Need this for blank pages
		if (reverseMap[i]) {
			// Get all fields on page 'i'
			const fields = reverseMap[i] as string[];
			for (const propName of fields) {
				const property = entry.meta.type.properties.find(p => p.name === propName);

				// This should only evaluate QLs on unavailable pages
				if (startingPage && endingPage && (i < startingPage || i > endingPage)) {
					if (!validateProperty(entry, property, null, true))
						return i;
					continue;
				}
				// If this field is not valid, return the page number
				if (!validateProperty(entry, property, null, false))
					return i;
			}
		}
	}
	return 0;
}

export function validateEntry(entity: Entity) {
	for (const prop of entity.meta.type.properties) {
		if (!validateProperty(entity, prop, null, false))
			return false;
	}
	return true;
}

// TODO: Do we need to transform the result? It seems like it would be easier to reason about if the data the client worked with was the same shape as the data the server responded with.
export function parseStoreResult(responseData: any) {
	const sr = responseData.submissionResult || { Status: 'Unknown' };

	// Return the entry submission result
	return {
		status: SubmissionResultStatus[sr.Status as string],
		message: responseData.exceptionMessage || sr.Message,
		entry: responseData.entry,
		order: responseData.order,
		responseData: sr.Data as QuantityData[],
		entryToken: responseData.entryToken,
		auditRecordId: sr.AuditRecordId
	};
}