import type { EntityOfType, TypeExtensionOptionsForType } from '@cognitoforms/model.js';
import { hasValue, $format } from '../../../util/helpers';
import type { Address } from '@cognitoforms/types/server-types/model/address';
import { getCountryCode } from '../countries';

export interface AddressMethods {
	validateRequiredAddress(this: EntityOfType<Address>, enforceableFields: string[]): string;
	validateZipCode(this: EntityOfType<Address>): string;
}

export const AddressExtensions: TypeExtensionOptionsForType<any> & AddressMethods = {
	Country: {
		type: String,
		set: function(this: EntityOfType<Address>, country: string) {
			const model = this.meta.type.model;
			if (country)
				this.CountryCode = getCountryCode(country.trim(), model.$locale || 'en');
			else
				this.CountryCode = null;
		}
	},
	validateRequiredAddress: function(this: EntityOfType<Address>, enforceableFields: string[]): string {
		// Store the model to use for localization when building the error message
		const model = this.meta.type.model;

		const countryLabelUS = model.getResource('united-states');

		let error = '';
		let errorCount = 0;

		// NOTE: Iterate through the list in reverse in order to avoid an Oxford comma in the error message...
		for (let f = enforceableFields.length - 1; f >= 0; f--) {
			const field = enforceableFields[f];

			// Skip this field if it has a value
			const value = this.get(field);
			if (hasValue(value)) continue;

			// Rename Postal Code to Zip Code if US Address
			let label = model.getResource('address-' + field.toLowerCase());
			if (field === 'PostalCode' && this.Country === countryLabelUS) label = model.getResource('address-zip-code');

			if (errorCount === 0) error = label;
			else if (errorCount === 1) error = $format(model.getResource('this-and-that'), label, error);
			else error = $format(model.getResource('this-comma-that'), label, error);

			errorCount++;
		}

		if (errorCount > 0)
			error = $format(errorCount === 1 ? model.getResource('required-one') : model.getResource('required-many'), error);

		return error;
	},
	validateZipCode: function(this: EntityOfType<Address>): string {
		// Store the model to use for localization when building the error message
		const model = this.meta.type.model;

		const countryLabelUS = model.getResource('united-states');

		if (this.Country === countryLabelUS) {
			// Make sure that a US zip code is used if United States is chosen as the country
			if (hasValue(this.PostalCode) && !/^\s*(\d{5})(-\d{4})?\s*$/.test(this.PostalCode)) {
				return model.getResource('address-zip-code-invalid');
			}
		}
	}
};
