import 'src/style/_toast-message.scss';

import { Message } from '@cognitoforms/element-ui';
import type { ElMessageComponent, ElMessageOptions } from '@cognitoforms/element-ui/types/message';
import IEx from './ToastMessageEx.vue';
import type { VNode } from 'vue';
import Vue from 'vue';

const ExClass = Vue.extend(IEx);
interface ElMessageComponentInternals extends ElMessageComponent
{
	message: '';
	closeAll: () => void;
}

type MessageComponent = ElMessageComponentInternals & ElMessageComponent;

type ToastListener = (formId: string, message: string | VNode) => void;
const toastListeners: ToastListener[] = [];
const messagesDisplayed = new Set<MessageComponent>();
export default function(formId: string, options: ElMessageOptions) {
	for (const callback of toastListeners)
		callback(formId, options.message);

	if (typeof options.message === 'string') {
		for (const messageInstance of messagesDisplayed) {
			if (messageInstance.message === options.message)
				return;
		}
		options.onClose = (messageInstance: MessageComponent) => {
			messagesDisplayed.delete(messageInstance);
		};
	}

	options.customClass = `cog-cognito cog-${formId} ` + (options.customClass || '');
	const messageInstance = Message({ duration: options.type === 'success' ? 3000 : 0, showClose: true, ...options });

	if (messageInstance.showClose) {
		const exInstance = new ExClass();
		exInstance.$mount();
		document.querySelector('.el-message:last-child .el-message__closeBtn').appendChild(exInstance.$el);
	}

	if (typeof options.message === 'string')
		messagesDisplayed.add(messageInstance);

	return messageInstance;
};

export function onToastMessageRequested(callback: ToastListener) {
	const unsubscribe = () => {
		const index = toastListeners.indexOf(callback);
		if (index >= 0)
			toastListeners.splice(index, 1);
	};
	toastListeners.push(callback);
	return unsubscribe;
}

export function closeAllToastMessages() {
	messagesDisplayed.forEach((message: MessageComponent) => {
		if (message.showClose)
			message.close();
	});
}