import Vue from 'vue';
import { CError } from './error';
import type { EntityOfType } from '@cognitoforms/model.js';
import type FormBase from 'src/components/FormBase';
import type { FormSession } from 'src/web-api/form-session';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type AppInsights from './app-insights';

const ignoredExceptions = new Set([
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'Script error.'
]);

const FAUX_KEY = 'faux-key';

export default class Log {
	constructor(session: FormSession) {
		this.session = session;
		if (!this.isEnabled)
			return;

		if (!Log.appInsights) {
			Log.appInsights = import(/* webpackChunkName: "app-insights" */ './app-insights').then(mod => {
				const AppInsightsCtor = mod.default;
				const appInsights = new AppInsightsCtor(FAUX_KEY, '/svc/log');

				appInsights.addTelemetryInitializer(Log.ignoreUselessErrors.bind(this));

				appInsights.addTelemetryInitializer(function ignoreItemsWithRealKey(item) {
					// when the admin site's global app insights instance is available, sometimes we
					// get telemetry items with the real instrumentation key
					return item.iKey === FAUX_KEY;
				});

				// append metadata to telemetry
				appInsights.addTelemetryInitializer(item => {
					if (item.baseData) {
						item.baseData.properties = item.baseData.properties || {};
						Object.assign(item.baseData.properties, this.metadata);
					}
				});

				// This logic is repeated in CleanseTelemetryProcessor.cs and ai.js (and ai.min.js)
				appInsights.addTelemetryInitializer(function cleanseUrls(item) {
					if (item.baseType === 'PageviewData') {
						item.baseData.uri = Log.cleanseUrl(item.baseData.uri);
					}

					if (item.baseType === 'RemoteDependencyData') {
						item.baseData.name = Log.cleanseUrl(item.baseData.name);
						item.baseData.target = Log.cleanseUrl(item.baseData.target);
					}
				});
				appInsights.addTelemetryInitializer(function filterSuccessful(item) {
					if (item.baseType === 'RemoteDependencyData') {
						const data = item.baseData;
						if (data) {
							if (data.responseCode.toString().startsWith('2') && data.duration < 30000) {
								return false;
							}
						}
					}
					return true;
				});
				return appInsights;
			});
		}

		// eslint-disable-line no-use-before-define
		Vue.config.errorHandler = Log.onError;
		Vue.config.warnHandler = Log.onWarning;
	}

	// -------------- Properties --------------
	private entry: EntityOfType<FormEntry>;
	private session: FormSession;
	private static appInsights: Promise<AppInsights>;

	// -------------- Computed --------------
	get isEnabled(): boolean {
		return this.session.flags.log;
	}

	get organizationId(): string {
		return this.session.organizationId;
	}

	get formName(): string {
		return this.entry && this.entry.Form ? this.entry.Form.InternalName : null;
	}

	get formId(): string {
		return this.session.formId;
	}

	get entryId(): string {
		return this.entry ? this.entry.Id : null;
	}

	// -------------- Methods --------------

	error(error: CError | Error, additionalProperties?: Record<string, unknown>) : void {
		const cError = error as CError;
		if (additionalProperties) {
			cError.additionalProperties = {
				...cError.additionalProperties,
				...additionalProperties
			};
		}

		Log.error(error as CError, this);
	}

	warn(message: string, trace?: string, additionalProperties?: Record<string, unknown>): void {
		Log.warn(message, this, trace, additionalProperties);
	}

	async pageLoad() {
		if (!this.isEnabled)
			return;

		(await Log.appInsights).pageView();
	}

	registerEntry(entry: EntityOfType<FormEntry>) {
		this.entry = entry;
	}

	async customEvent(eventName: string, additionalProperties?: Record<string, unknown>) {
		Log.customEvent(eventName, this, additionalProperties);
	}

	async metric(metricName: string, amount: number, additionalProperties?: Record<string, unknown>) {
		Log.metric(metricName, amount, this, additionalProperties);
	}

	async trace(message: string, additionalProperties?: Record<string, unknown>) {
		if (this.isEnabled) {
			(await Log.appInsights).logTrace(message, additionalProperties);
		}
	}

	private static async error(error: CError, log: Log) {
		console.error(error.message, error);

		// Some errors are only for the end-user. A more detailed error was probably already logged
		if (!log.isEnabled || error.log === false)
			return;

		(await Log.appInsights).log(error, error.additionalProperties);

		// Prevent the error from being logged again by additional throws or subsequent calls to `logError`
		error.log = false;
	}

	private static async warn(message: string, log: Log, trace?: string, additionalProperties?: Record<string, unknown>) {
		if (!log || !log.isEnabled)
			return;

		const error = new CError(message);
		error.stack = trace;

		(await Log.appInsights).log(error, { isWarning: 'true', ...additionalProperties });

		console.warn(error);
	}

	private static async customEvent(eventName: string, log: Log, additionalProperties: Record<string, unknown>) {
		if (!log || !log.isEnabled)
			return;

		(await Log.appInsights).logCustomEvent(eventName, additionalProperties);
	}

	private static async metric(metricName: string, amount: number, log: Log, additionalProperties: Record<string, unknown>) {
		if (!log || !log.isEnabled)
			return;

		(await Log.appInsights).logMetric(metricName, amount, additionalProperties);
	}

	private get metadata() {
		return {
			'OrganizationId': this.organizationId,
			'FormName': this.formName,
			'FormId': this.formId,
			'EntryId': this.entryId,
			'FormClient': true
		};
	}

	private static patterns = [
		/(\?|&)token(=[^&]*)?|^token(=[^&]*)?&?/ig, // Remove session tokens
		/[=#:](.{44})[*!]/ig, // Remove save & resume tokens
		/F-[!$0-9a-zA-Z]{22}/ig // Remove file ID references
	];

	private static blankOut = '*****';

	public static cleanseUrl(url: string) {
		if (!url.toLowerCase().includes('http') || url === '') {
			return url;
		}

		// Replace all sensitive information before reporting to AI
		for (let i = 0; i < this.patterns.length; i++) {
			url = url.replaceAll(this.patterns[i], this.blankOut);
		}

		return url;
	}

	public static ignoreUselessErrors(item: any): boolean {
		if (item.baseType === 'ExceptionData' && ignoredExceptions.has(item.data.message)) {
			return false;
		}
		else {
			return true;
		}
	}

	private static getInstance(obj: Vue | FormBase): Log {
		return obj ? (obj.$root as FormBase).log as Log : null;
	}

	private static onError(error: Error, $vm: Vue): void {
		if (ignoredExceptions.has(error.message))
			return;

		Log.error(error, Log.getInstance($vm));
	}

	private static onWarning(message: string, $vm: Vue, trace: string): void {
		Log.warn(message, Log.getInstance($vm), trace);
	}
}