import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';

export default class TimeConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.propertyType === Date && prop.format.specifier === 't';
	}

	serialize(context: Entity, value: any, prop: Property): PropertySerializationResult {
		return {
			key: prop.name,
			value: value ? ((value.getHours() < 10 ? '0' : '') + value.getHours()
				+ (value.getMinutes() < 10 ? ':0' : ':') + value.getMinutes()
				+ (value.getSeconds() < 10 ? ':0' : ':') + value.getSeconds())
				: null
		};
	}

	deserialize(context: Entity, value: any, prop: Property) {
		if (typeof value === 'string') {
			const fullDate = new Date(value);

			// if a date could not be created from the time data provided, then assume the value is only the time component and
			// deserialize to a common date
			if (isNaN(fullDate.getDate()))
				return new Date(1970, 0, 1, Number(value.substr(0, 2)), Number(value.substr(3, 2)), Number(value.substr(6, 2)));
			else
				return fullDate;
		}
		return value;
	}
}