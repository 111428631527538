export function toBase62(number: number): string {
	if (typeof number === 'undefined' || number === null) {
		return '';
	}

	if (typeof number !== 'number') {
		throw new Error('Function `toBase62` recieved invalid \'number\' parameter of type \'' + (typeof number) + '\'.');
	}
	else if (number === 0) {
		return '';
	}

	const digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

	let text = '';

	let value = number;

	while (value > 0) {
		const remainder = value % 62;
		value = Math.floor(value / 62);
		text = digits.charAt(remainder) + text;
	}

	return text;
}

export function fromBase62(text: string): number {
	if (typeof text === 'undefined' || text === null) {
		return 0;
	}

	if (typeof text !== 'string') {
		throw new Error('Function `fromBase62` recieved invalid \'text\' parameter of type \'' + (typeof text) + '\'.');
	}
	else if (text.length === 0) {
		return 0;
	}

	const digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

	let number = 0;

	for (let i = text.length - 1; i >= 0; i--) {
		const c = text[i];
		const idx = digits.indexOf(c);
		const b = text.length - 1 - i;
		const pow = Math.pow(62, b);
		number += pow * idx;
	}

	return number;
}
