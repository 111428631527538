import type { Order } from '@cognitoforms/types/server-types/payment/model/order';
import type { FormsModel } from 'src/framework/forms-model';
import type { EntityOfType, Type } from '@cognitoforms/model.js';
import type { FormEntryWithOrder, FormEntryWorkflowExtensions } from './form-entry-extensions';

export function applyPaymentExtensions(model: FormsModel, entryType: Type) {
	// If the entry does not have an Order property, no payment extensions should be applied
	if (entryType.getProperty('Order')) {
		if (entryType.getProperty('Next_Status')) {
			entryType.extend({
				// For workflow forms, payment is only truly required if the next status is not Incomplete
				Require_Payment: {
					type: Boolean,
					get: {
						function(this: EntityOfType<FormEntryWithOrder & FormEntryWorkflowExtensions>) {
							return this.Require_Payment_Expression && this.Next_Status !== 'Incomplete';
						},
						dependsOn: '{Require_Payment_Expression, Next_Status}'
					}
				}
			});
		}
		else {
			entryType.extend({
				Require_Payment: {
					type: Boolean,
					get: {
						function(this: EntityOfType<FormEntryWithOrder>) {
							return this.Require_Payment_Expression;
						},
						dependsOn: 'Require_Payment_Expression'
					}
				}
			});
		}

		// conditionally add Save_Card_Agreement property for checkbox
		if (entryType.getProperty('Save_Customer_Card')) {
			entryType.extend({
				Save_Card_Agreement: {
					type: Boolean,
					required: {
						message: model.model.getResource('card-on-file-agreement-validation'),
						function() { return true; }
					}
				}
			});
		}

		// default the order property if present
		const newOrderState = {
			IsOpen: true
		};

		entryType.initExisting.subscribeOne(e => {
			if (!e.entity['Order'])
				e.entity.update('Order', newOrderState);
		});

		entryType.extend({
			Order: {
				default() {
					return model.construct<Order>('Payment.Order', newOrderState);
				}
			}
		});

		const orderType = model.model.types['Payment.Order'];

		if (orderType)
			orderType.extend({
				Rebuild_Count: {
					type: Number,
					default: 0
				},
				SubTotal: {
					get: {
						function() {
							return this.LineItems.reduce((subtotal, item) => subtotal + item.Amount, 0);
						},
						dependsOn: 'LineItems{Amount}'
					}
				},
				AdditionalFees: {
					get: {
						function() {
							return this.Fees.filter(fee => !fee.IsProcessingFee).reduce((total, fee) => total + fee.Amount, 0);
						},
						dependsOn: 'Fees{IsProcessingFee,Amount}'
					}
				},
				ProcessingFees: {
					get: {
						function() {
							return this.Fees.filter(fee => fee.IsProcessingFee).reduce((total, fee) => total + fee.Amount, 0);
						},
						dependsOn: 'Fees{IsProcessingFee,Amount}'
					}
				},
				OrderAmount: {
					get: {
						function() {
							return this.SubTotal + this.AdditionalFees + this.ProcessingFees;
						},
						dependsOn: '{SubTotal,AdditionalFees,ProcessingFees}'
					}
				},
				AmountDue: {
					get: {
						function() {
							return this.OrderAmount - this.AmountPaid;
						},
						dependsOn: '{OrderAmount,AmountPaid}'
					}
				},
				OrderSummary: {
					get: {
						function() {
							const status = this.PaymentStatus.DisplayName;
							switch (status) {
								case 'Paid':
									return this.toString('[AmountPaid] [PaymentStatus.Name]');
								case 'Refunded':
									return this.toString('[RefundAmount] [PaymentStatus.Name]');
								case 'Declined':
									// if AmountDeclined is falsy, OrderSummary should show OrderAmount
									if (this.AmountDeclined)
										return this.toString('[AmountDeclined] [PaymentStatus.Name]');
								// eslint-disable-next-line no-fallthrough
								default:
									return this.toString('[OrderAmount] [PaymentStatus.Name]');
							}
						},
						dependsOn: '{PaymentStatus,AmountPaid,RefundAmount,OrderAmount,AmountDeclined}'
					}
				}
			});
	}
}

export type OrderExtensions = {
	Rebuild_Count: number;
}
