import type { Entity, Property, Type, EntityOfType, TypeOfType, EntityConstructor } from '@cognitoforms/model.js';
import { Property$pendingInit } from '@cognitoforms/model.js';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { FormEntryIndex } from '@cognitoforms/types/server-types/forms/model/form-entry-index';
import { Deferred } from 'src/util/deferred';
import EntrySet from 'src/web-api/entry-views/entry-set';
import type EntryViewService from 'src/web-api/entry-views/entry-view-service';
import { getEntryId } from 'src/web-api/entry-views/entry-view-util';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import type { FormsModel } from '../../../forms-model';

const FILTER_RECURSION_LIMIT = 12;

export type LookupIndexInfo = {
	viewId: string;
	indexTypeName: string;
	summaryFormat: string;
	descriptionFormat: string;
	filterPaths: string[];
	defaultPaths: string[];
};

export type LookupEntryIndex = FormEntryIndex & {
	Default?: boolean;
}

export type LookupEntryIndexMethods = {
	IsIncluded(container: Entity): boolean;
	IsDefaulted(container: Entity): boolean;
}

/**
 * It is currently possible for two lookup fields to have different index types,
 * but share a lookup view. This results in trying to construct an index with an
 * already-pooled id. The long term fix is to ensure these fields use the same type,
 * but for compatibility reasons it is currently simpler to handle this edge case
 * by obtaining the pooled instance via the baseType.
 */
function getPooledIndex<T extends FormEntryIndex>(type: Type, id: string): EntityOfType<T> | null {
	while (type) {
		const instance = type.get(id);
		if (instance)
			return instance as EntityOfType<T>;
		type = type.baseType;
	}
	return null;
}

export function getIndex(model: FormsModel, indexType: Type, state: FormEntryIndex): EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods {
	if (state.Id) {
		const pooledIndex = getPooledIndex<LookupEntryIndex>(indexType, state.Id);
		if (pooledIndex)
			return pooledIndex as EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods;
	}

	if (state.AdditionalValues) {
		for (const av of state.AdditionalValues) {
			let val: any = av.Value;
			const prop = indexType.getProperty(av.FieldId);

			if (prop) {
				if (prop.isList && typeof val === 'string')
					val = JSON.parse(val);
				else if (prop.propertyType === Boolean)
					val = val === 'True';
				else if (prop.propertyType === Number)
					val = Number(val);
			}

			state[av.FieldId] = val;
		}
	}

	return model.construct<LookupEntryIndex>(indexType.fullName, state) as EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods;
}

@Component
export default class LookupManager extends Vue {
	@Prop()
	viewId: string;

	@Prop()
	indexType: Type;

	@Prop()
	summaryFormat: string;

	@Prop()
	descriptionFormat: string;

	@Prop()
	container: Entity;

	@Prop()
	lookupProperty: Property;

	@Prop()
	entryViewService: EntryViewService;

	entrySet: EntrySet;
	indexes: (EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods)[] = [];
	initializing = false;
	isValid = true;
	// Indexes that are no longer part of the entry set, but represent the current value for the lookup field
	syntheticIndexes = new Set<EntityOfType<LookupEntryIndex>>();
	filteredIndexes: (EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods)[] = [];
	defaultIndexes: (EntityOfType<LookupEntryIndex> & LookupEntryIndexMethods)[] = [];
	private readyTask: Deferred;

	async created() {
		if (this.entryViewService.isValidView(this.viewId))
			this.initialize();
		else
			this.isValid = false;
	}

	async initialize() {
		this.initializing = true;
		this.readyTask = new Deferred();
		this.entrySet = await EntrySet.get(this.entryViewService, this.entryType.fullName, this.viewId);
		await this.entrySet.refresh();

		const indexes = this.entrySet.listIndex().map(o => getIndex(this.entryViewService.model, this.indexType, o));

		if (!this.container.meta.isNew) {
			const missing = ((this.lookupProperty.isList ? this.listValue : [this.value])).filter(e => e && !this.entrySet.getIndexItem(e.Id));

			for (const entry of missing) {
				const syntheticIndex = getIndex(this.entryViewService.model, this.indexType, {
					Id: `${this.entrySet.view}|fake|${entry.Id}`,
					Summary: entry.toString(this.summaryFormat),
					Description: this.descriptionFormat ? '- ' + entry.toString(this.descriptionFormat) : ''
				} as any);

				this.syntheticIndexes.add(syntheticIndex);
				indexes.unshift(syntheticIndex);
			}
			this.$emit('synthetic-indexes-changed', this.syntheticIndexes);
		}

		this.indexes = indexes;

		// allow computed/watchers to run before setting loading to false
		await this.$nextTick();
		await this.container.initialized;

		this.updateFilteredIndexes();

		this.initializing = false;

		this.readyTask.resolve();
	}

	whenReady(callback: () => void): void {
		if (this.initializing)
			this.ready.then(callback);
		else
			callback();
	}

	get allowUnknownEntry() {
		return !this.container.meta.isNew;
	}

	get entryType() {
		return (this.lookupProperty.propertyType as EntityConstructor).meta as TypeOfType<FormEntry>;
	}

	get ready() {
		return this.readyTask.promise;
	}

	get isList() {
		return this.lookupProperty.isList;
	}

	get value() {
		return this.lookupProperty.value(this.container) as EntityOfType<FormEntry>;
	}

	get listValue() {
		return this.lookupProperty.value(this.container) as EntityOfType<FormEntry>[];
	}

	get hasValue() {
		return this.isList ? this.listValue.length > 0 : !!this.value;
	}

	private isUpdatingFilter = false;
	private recursionDepth = 0;

	updateFilteredIndexes(fromRule: boolean = false) {
		// prevent recursion due to defaulting triggering filter evaluation
		if (this.isUpdatingFilter) {
			this.recursionDepth++;
			if (this.recursionDepth > FILTER_RECURSION_LIMIT)
				return;
		}

		if (!this.indexes)
			this.filteredIndexes = [];
		else
			this.filteredIndexes = this.indexes.filter(o => {
				if (!o.IsIncluded)
					return true;
				try {
					return o.IsIncluded(this.container);
				}
				catch (e) {
					return false;
				}
			});

		if (fromRule)
			this.clearInvalidValues();

		this.isUpdatingFilter = true;
		try {
			this.updateDefaultIndexes(fromRule);
		}
		finally {
			setTimeout(() => {
				this.isUpdatingFilter = false;
				this.recursionDepth = 0;
			});
		}
	}

	/**
	 * When the filtered options no longer include the currently selected value, clear the value.
	 */
	clearInvalidValues() {
		const filteredIndexes = this.filteredIndexes;
		if (this.isList) {
			if (this.listValue && this.listValue.some(entry => !filteredIndexes.some(o => getEntryId(o) === entry.Id)))
				this.setLookupValue(this.listValue.filter(entry => filteredIndexes.some(o => getEntryId(o) === entry.Id)));
		}
		else {
			if (this.value && !filteredIndexes.some(o => getEntryId(o) === this.value.Id))
				this.setLookupValue(null);
		}
	}

	updateDefaultIndexes(fromRule: boolean = false) {
		function staticDefault(option: EntityOfType<FormEntryIndex>) {
			return option.AdditionalValues.some(v => v.FieldId === 'Default' && v.Value === 'True');
		}

		const oldDefaultIndexes = this.defaultIndexes;

		if (!this.indexes)
			this.defaultIndexes = [];
		else
			this.defaultIndexes = this.filteredIndexes.filter(o => {
				if (staticDefault(o))
					return true;
				try {
					return o.IsDefaulted && o.IsDefaulted(this.container);
				}
				catch (e) {
					return false;
				}
			});

		if (fromRule)
			this.setDefaultValue(oldDefaultIndexes.length !== 0);
	}

	// used to represent the most current "id" of the invocation of the function defaultChanged
	private lastSetDefaultValueId = 0;

	/**
	 * Sets a default value (or values) for the lookup using the given default indexes.
	 */
	async setDefaultValue(previouslyHadDefaultIndexes: boolean) {
		this.lastSetDefaultValueId++;
		const setDefaultValueId = this.lastSetDefaultValueId;

		const defaultIndexes = this.defaultIndexes;
		if (defaultIndexes.length) {
			if (this.isList) {
				const entries = await Promise.all(defaultIndexes.map(o => getEntryId(o)).map(id => this.getEntryById(id)));
				if (setDefaultValueId === this.lastSetDefaultValueId)
					this.setLookupValue(entries);
			}
			else {
				const entry = await this.getEntryById(getEntryId(defaultIndexes[0]));
				if (setDefaultValueId === this.lastSetDefaultValueId)
					this.setLookupValue(entry);
			}
		}
		// Only clear out selected values due to no default indexes if there previously were default indexes.
		else if (previouslyHadDefaultIndexes) {
			this.setLookupValue(this.isList ? [] : null);
		}
	}

	getEntryById(id: string, ignoreFilter: boolean = false): Promise<EntityOfType<FormEntry>> {
		return this.entrySet.getEntry(ignoreFilter ? this.indexes.find(o => getEntryId(o) === id) : this.filteredIndexes.find(o => getEntryId(o) === id));
	}

	setLookupValue(value: EntityOfType<FormEntry> | EntityOfType<FormEntry>[]) {
		const isInited = this.lookupProperty.isInited(this.container) && !Property$pendingInit(this.container, this.lookupProperty);
		this.lookupProperty.value(this.container, value, {
			isInited
		});
	}
}

export type LookupManagerOptions = {
	lookupInfo: LookupIndexInfo,
	indexType: Type;
	lookupProperty: Property;
	container: Entity;
	entryViewService: EntryViewService;
};

export type LookupManagerFactory = (options: LookupManagerOptions) => LookupManager;

export function getLookupManager({ lookupInfo, indexType, lookupProperty, container, entryViewService }: LookupManagerOptions) {
	return new LookupManager({
		propsData: {
			entryViewService,
			viewId: lookupInfo.viewId,
			indexType,
			container,
			lookupProperty,
			summaryFormat: lookupInfo.summaryFormat,
			descriptionFormat: lookupInfo.descriptionFormat
		}
	});
}