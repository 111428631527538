import type { FormOwner } from '../public/form-handle';
import { FormHandle } from '../public/form-handle';
import type FormBase from 'src/components/FormBase';
import type FormDefinition from '../form-definition';
import { Deferred } from 'src/util/deferred';

/**
 * Admin API for interacting with a form in the admin experience
 */
export class AdminFormHandle extends FormHandle {
	form: FormBase = null;
	formDef: FormDefinition = null;

	private _formAvailable = new Deferred();

	constructor(formId: string, owner: FormOwner, ready: Promise<any>) {
		super(formId, owner, ready);
	}

	attach(form: FormBase) {
		this.form = form;
		this._formAvailable.resolve();
	}

	protected get formAvailable() {
		return this._formAvailable.promise;
	}

	destroy() {
		this.formDef = null;
		this.handlers.clear();

		if (this.form) {
			try {
				// Destroy the component
				this.form.$destroy();
			}
			catch (e) {
				// Ignore errors when destroying the form
			}

			// Remove the element from the DOM
			if (this.form.$el)
				this.form.$el.remove();
		}
	}
}
