import type { PropertyInjector, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
/**
 * Adds properties specific to Cognito storage layer.
 */
export default class StoragePropertyInjector implements PropertyInjector {
	inject(entity: Entity): PropertySerializationResult[] {
		const results = [];
		if (entity.meta.type.baseType)
			results.push({ key: '$type', value: 'Cognito.' + entity.meta.type.fullName });
		if (entity['$version'])
			results.push({ key: '$version', value: entity['$version'] });
		if (entity['$etag'])
			results.push({ key: '$etag', value: entity['$etag'] });
		return results;
	}
}