import type { Property, Entity } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';

/**
 * Preserves legacy prefill options for Yes/No fields
 */
export default class YesNoConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.propertyType === Boolean;
	}

	deserialize(context: Entity, value: any, prop: Property) {
		// Needed to handle legacy string "true" and "false" variants
		if (typeof value === 'string') {
			if (value.toLowerCase() === 'true')
				value = true;
			else if (value.toLowerCase() === 'false')
				value = false;
			else if (prop.defaultValue !== undefined) {
				value = prop.format.convertFromString(value);
				if (value === null)
					value = prop.defaultValue;
			}
		}
		return value;
	}
}