import countries from 'i18n-iso-countries';

const countryNameWithParensExpr = /^([^(]+)\s+[（(]([^)]+)[)）]\s*$/g;
const countryNameWithCommaExpr = /^([^,]+),\s+([^)]+)\s*$/g;

export function getCountryCode(name: string, locale: string): string {
	let countryCode = countries.getAlpha2Code(name, locale);
	if (countryCode)
		return countryCode;

	// If the country name has an alternative name or some other text in parentheses,
	// then remove the parenthesized value and try again.
	if (countryNameWithParensExpr.test(name)) {
		const cleansedName = name.replace(countryNameWithParensExpr, '$1');
		countryCode = countries.getAlpha2Code(cleansedName, locale);
		if (countryCode)
			return countryCode;
	}

	// If the country name has an alternative name after a comma,
	// then exclude the portion after the comma and try again.
	if (countryNameWithCommaExpr.test(name)) {
		const cleansedName = name.replace(countryNameWithCommaExpr, '$1');
		countryCode = countries.getAlpha2Code(cleansedName, locale);
		if (countryCode)
			return countryCode;
	}

	// Try to find the country by replacing spaces with dashes
	if (name.includes(' ')) {
		const nameWithoutSpaces = name.replaceAll(' ', '-');
		countryCode = countries.getAlpha2Code(nameWithoutSpaces, locale);
		if (countryCode)
			return countryCode;
	}

	return null;
}
