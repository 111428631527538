/**
 * Determine whether the given value satisfies "required" validation
 * NOTE: Taken from 'RequiredRule.hasVavlue' in ExoWeb
 * @param val The value to test for requiredness
 */
export function hasValue(val: any): boolean {
	return val !== undefined && val !== null && (typeof val !== 'string' || val.trim() !== '') && (!(val instanceof Array) || val.length > 0);
}

/**
 * Evaluates a numerical index based format string, akin to `String.format()` in .NET.
 * NOTE: Take from $format in ExoWeb...
 * @param template The format template string
 * @param values The values to insert for tokens in the template string
 */
export function $format(template: string): string;
export function $format(template: string, values: unknown[]): string;
export function $format(template: string, ...values: unknown[]): string;
export function $format(template: string, ...args: unknown[]): string {
	if (!args || args.length === 0) return template;

	const source = args.length === 1 && (args[0] instanceof Array)
		? args[0]
		: args;

	return template.replace(/\{([0-9]+)\}/ig, function (match: string, p1: string): string {
		const index = parseInt(p1, 10);
		let result = source[index];

		if (result !== null && result !== undefined && typeof result !== 'string') {
			// Convert non string values to string
			result = result.toString();
		}

		return result;
	});
}

/**
 * Shorthand for `Object.hasOwnProperty`
 */
// todo: necessary?
export function hasProp(obj: any, prop: string): boolean {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Clones the top-level own properties of an object
 * @param obj The object to clone
 */
// todo: necessary? Object.assign() accomplishes the same
export function shallowClone<T extends object>(obj: T): T {
	const result: any = {};
	for (const prop in obj) {
		if (hasProp(obj, prop)) {
			result[prop] = obj[prop];
		}
	}
	return result;
}

/**
 * Performs a deep removal of null and undefined properties from an object.
 * @param obj The object from which to remove properties
 */
export function removeEmptyProps(obj) {
	Object.keys(obj).forEach(k => {
		if (obj[k] && typeof obj[k] === 'object')
			removeEmptyProps(obj[k]);
		else if (obj[k] === null || obj[k] === undefined)
			delete obj[k];
	});
	return obj;
};

/**
 * Gets the precision of the format
 * @param format String of the format ex. "C", "P1", "N0"
 * @returns Number
 */
export function getFormatPrecision(format: string) {
	if (format === 'C')
		return 2;

	let precision = parseInt(format.replace(/[^0-9]/, '')) || 0;

	if (format.startsWith('P'))
		precision += 2;

	return precision;
}

/**
 * Returns a string with the first letter Capitalized
 * @param val
 * @returns String
 */
export function capitalizeString(val: string) {
	if (!val)
		return val;
	else
		return val.charAt(0).toUpperCase() + val.substr(1);
}

/**
 * Recursively capitalizes property keys within an object. Does not modify the provided object.
 * @param obj The object on which to recursively capitalize property keys.
 * @returns Object
 */
export function capitalizeKeys(obj) {
	if (!obj)
		return;
	else if (typeof obj === 'string')
		return obj;

	const keys = Object.keys(obj);
	let n = keys.length;
	const newObj = {};
	while (n--) {
		const key: string = keys[n];
		let data;
		if (Array.isArray(obj[key]))
			data = obj[key].map(z => {
				return capitalizeKeys(z);
			});
		else if (typeof(obj[key]) === 'object')
			data = capitalizeKeys(obj[key]);
		else
			data = obj[key];

		newObj[capitalizeString(key)] = data;
	}
	return newObj;
};

export function removeExtraSpace(text: string): string {
	return text.replace(/ {2,}/g, ' ').trim();
};

export function escapeHtml(value: string) {
	return value ? value
		.replace(/&/g, '&amp;')
		.replace(/""/g, '&quot;')
		.replace(/'/g, '&#39;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		: value;
}
