import { isEntity, PropertyConverter, isEntityType } from '@cognitoforms/model.js';
import type { Property, Entity } from '@cognitoforms/model.js';
import { FORM_ENTRY_TYPE_NAME } from 'src/framework/forms-model';

/**
 * Even though we generate rules to ensure the back references are set when entities
 * are initialized, we need this converter to prevent default value rules from being
 * triggered during the initialization of existing entries.
 */
export default class InitializeBackReferencesConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		const shouldConvert = prop.name !== 'ParentSection' && prop.name !== 'Form'
			&& isEntityType(prop.propertyType)
			&& (!!prop.propertyType.meta.getProperty('ParentSection') || !!prop.propertyType.meta.getProperty('Form'));

		return shouldConvert;
	}

	deserialize(context: Entity, value: any, prop: Property) {
		const type = isEntityType(prop.propertyType) && prop.propertyType.meta;
		if (value) {
			if (Array.isArray(value))
				value = value.map(item => this.deserialize(context, item, prop));
			else {
				// avoid modifying the provided object
				if (!isEntity(value))
					value = Object.assign({}, value);

				if (type.getProperty('ParentSection'))
					value.ParentSection = context;
				if (type.getProperty('Form'))
					value.Form = context.meta.type.baseType.fullName === FORM_ENTRY_TYPE_NAME
						? context
						: context.Form;
			}
		}

		return value;
	}
}
