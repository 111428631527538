import Vue from 'vue';
import type { Entity, TypeExtensionOptionsForType, CultureInfo, ModelOptions, ModelLocalizationOptions, AsyncValueResolver, Property, EntityOfType, TypeOfType, EntityConstructorForType, EntityArgsOfType } from '@cognitoforms/model.js';
import VueModel, { preventVueObservability } from '@cognitoforms/vuemodel';
import DateConverter from './model/serialization/converters/date-converter';
import TimeConverter from './model/serialization/converters/time-converter';
import OrderConverter from './model/serialization/converters/order-converter';
import EnumConverter from './model/serialization/converters/enum-converter';
import IgnoreCircularReferenceConverter from './model/serialization/converters/ignore-circular-reference-converter';
import InitializeBackReferencesConverter from './model/serialization/converters/initialize-back-references-converter';
import StoragePropertyInjector from './model/serialization/injectors/storage-property-injector';
import { AddressExtensions } from './model/extensions/address-extensions';
import { NameExtensions } from './model/extensions/name-extensions';
import { SignatureExtensions } from './model/extensions/signature-extensions';
import CalculationConverter from './model/serialization/converters/calculation-converter';
import DateTimeConverter from './model/serialization/converters/date-time-converter';
import type { ObjectLookup } from 'src/util';
import LookupConverter from './model/serialization/converters/lookup-converter';
import type EntryViewService from 'src/web-api/entry-views/entry-view-service';
import { getIdFromState } from '@cognitoforms/model.js';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { FormEntryRuleMethods } from './model/extensions/form-entry-extensions';
import type { WorkflowAction } from '@cognitoforms/types/server-types/forms/model/workflow-action';
import YesNoConverter from './model/serialization/converters/yesno-converter';
import EntryExtensionConverter from './model/serialization/converters/entry-extension-converter';
import { getEnumInstance } from './model/core';
import { applyPaymentExtensions } from './model/extensions/payment-extensions';
import type { LookupManagerFactory } from './model/extensions/lookup-field/lookup-manager';
import type { CascadeFilterManagerFactory } from './model/extensions/lookup-field/cascade-filter-manager';
import { applyEntityInitExtensions } from './model/extensions/entity-init-extensions';
import { extendModelWithLookups } from './model/extensions/lookup-field/lookup-model-extension';
import IgnoreFileUploadConverter from './model/serialization/converters/ignore-fileupload-converter';
import IgnoreSignaturePropertiesConverter from './model/serialization/converters/ignore-signature-properties-converter';
import { applyWorkflowActionExtensions } from './model/extensions/workflow-action-extensions';
import EntryMetaConverter from './model/serialization/converters/entry-meta-converter';
import type { EntryMeta } from '@cognitoforms/types/server-types/forms/model/entry-meta';
import countries from 'i18n-iso-countries';
import { unregisterEntity } from 'src/util/model';

export const FORM_ENTRY_TYPE_NAME = 'Forms.FormEntry';
export const ORDER_TYPE_NAME = 'Payment.Order';

type PropertyLookupViewMapping = {
	typeName: string;
	propName: string;
	viewId: string;
}

type EnumIntegerValues = { [key: number]: string };
type EnumStringValues = string[];
type EnumValuesLookup = ObjectLookup<EnumIntegerValues | EnumStringValues>;

export type FormsModelOptions = ModelOptions<any> & {
	$locale?: string;
	$culture?: string | CultureInfo;
	$namespace: object;
	$version: number;
	$utcOffset: number;
	$disableWorkflowActions?: boolean;
	$disableLookupFiltering?: boolean;
}

export type PublicFormsModelOptions = Pick<FormsModelOptions, '$disableWorkflowActions' | '$disableLookupFiltering'>;

export class FormsModel {
	readonly entryTypeName: string;
	readonly modelOptions: FormsModelOptions;
	private lookupValueResolver: AsyncValueResolver;
	model: VueModel;

	private static definedLocales = new Set<string>();

	private constructor(entryTypeName: string, modelOptions: FormsModelOptions, locale: string, localeResources: ObjectLookup<string>, cultureInfo: any) {
		// Prevent Vue from making the model options observable, since it will have a cascading effect
		if (modelOptions)
			preventVueObservability(modelOptions);
		this.entryTypeName = entryTypeName;
		this.modelOptions = modelOptions;
		const enumValues = FormsModel.captureEnumValues(modelOptions);
		const model = this.model = new VueModel(FormsModel.preprocessOptions(modelOptions, locale, localeResources, cultureInfo), {
			autogeneratePropertyLabels: false,
			maxEventScopeDepth: 100,
			maxExitingEventScopeTransferCount: 500
		});
		this.configureSerialization(modelOptions);
		FormsModel.applyCustomTypeExtensions(model, { Address: AddressExtensions, Name: NameExtensions, Signature: SignatureExtensions });

		if (!modelOptions.$disableWorkflowActions)
			applyWorkflowActionExtensions(this, this.resolveType(this.entryTypeName), FormsModel.captureActionData(this.modelOptions));

		applyPaymentExtensions(this, this.resolveType(this.entryTypeName));
		applyEntityInitExtensions(this);
		this.model.ready(() => FormsModel.postprocessModel(model, enumValues));
	}

	/**
	 * The global config object shared by form models
	 * NOTE: This is currently only needed to provide the `serverTimeOffset` required by the `Cognito_now` export used in model extensions
	 */
	static config: any = {};

	/**
	 * Detect model options that are types that appear to be enums and capture their enum values
	 */
	private static captureEnumValues(modelOptions: FormsModelOptions): EnumValuesLookup {
		return Object.keys(modelOptions).reduce((lookup, key) => {
			const value = modelOptions[key];
			if (typeof value === 'object' && value.$enum)
				lookup[key] = value.$enum as EnumIntegerValues | EnumStringValues;
			return lookup;
		}, {});
	}

	/**
	 * Capture the workflow actions configuration for the form from the '$data' field on each WorkflowAction sub-type
	 */
	private static captureActionData(modelOptions: FormsModelOptions): { [type: string]: WorkflowAction } {
		return Object.keys(modelOptions).reduce((lookup, key) => {
			const value = modelOptions[key];
			if (typeof value === 'object' && value.$extends && value.$extends === 'Forms.WorkflowAction') {
				lookup[key] = value.$data;
			}
			return lookup;
		}, {});
	}

	/**
	 * Preprocess model options before passing them to the model constructor
	 */
	private static preprocessOptions(options: FormsModelOptions, locale: string, localeResources: { [name: string]: string }, cultureInfo: CultureInfo): ModelOptions<any> & ModelLocalizationOptions {
		const modelOptions: ModelOptions<any> & ModelLocalizationOptions = options;
		// Set the 'Cognito.config' reference to a shared config object
		modelOptions.$namespace['config'] = FormsModel.config;
		(modelOptions.$namespace as any)['session'] = { utcOffset: options.$utcOffset };

		// Copy the resources object for internal use by the model
		modelOptions.$resources = { [locale]: Object.assign({}, localeResources) };

		// Parse the culture object
		if (cultureInfo)
			modelOptions.$culture = VueModel.CultureInfo.parse(cultureInfo);

		return modelOptions;
	}

	/**
	 * Configures the given model's serializer
	 * @param model The model to configure
	 */
	private configureSerialization(modelOptions: ObjectLookup<any>): void {
		const model = this.model;
		const lookupMappings: PropertyLookupViewMapping[] = [];
		for (const [typeName, typeDef] of Object.entries(modelOptions)) {
			if (!typeName.startsWith('$')) {
				if (typeDef.$storageProperties) {
					for (const alias in typeDef.$storageProperties)
						model.serializer.registerPropertyAlias(typeName, alias, typeDef.$storageProperties[alias]);
				}

				for (const [propName, propDef] of Object.entries(modelOptions)) {
					if (typeof propDef === 'object' && propDef.$lookupFieldInfo)
						lookupMappings.push({ typeName, propName, viewId: propDef.$lookupFieldInfo.viewId });
				}
			}
		}

		model.serializer.registerValueResolver(this.initialValueResolver.bind(this));

		// Order matters
		model.serializer.registerPropertyConverter(new EntryMetaConverter());
		model.serializer.registerPropertyConverter(new IgnoreSignaturePropertiesConverter());
		model.serializer.registerPropertyConverter(new IgnoreFileUploadConverter(this.entryTypeName));
		model.serializer.registerPropertyConverter(new DateConverter());
		model.serializer.registerPropertyConverter(new YesNoConverter());
		model.serializer.registerPropertyConverter(new TimeConverter());
		model.serializer.registerPropertyConverter(new DateTimeConverter());
		model.serializer.registerPropertyConverter(new CalculationConverter());
		model.serializer.registerPropertyConverter(new IgnoreCircularReferenceConverter());
		model.serializer.registerPropertyConverter(new InitializeBackReferencesConverter());
		model.serializer.registerPropertyConverter(new LookupConverter());
		model.serializer.registerPropertyConverter(new OrderConverter());
		model.serializer.registerPropertyConverter(new EntryExtensionConverter());
		model.serializer.registerPropertyConverter(new EnumConverter());

		model.serializer.registerPropertyInjector(FORM_ENTRY_TYPE_NAME, new StoragePropertyInjector());
	}

	/**
	 * Applies custom type extensions for any of the given types that are in the given model
	 * @param model The model to extend
	 * @param typeExtensions The custom type extensions to apply
	 */
	private static applyCustomTypeExtensions(model: VueModel, typeExtensions: { [typeName: string]: TypeExtensionOptionsForType<unknown> }): void {
		// Apply custom type extensions for types that are included in the model
		Object.keys(typeExtensions).forEach((typeName) => {
			const type = model.types[typeName];
			if (type) {
				const typeExtension = typeExtensions[typeName];
				type.extend(typeExtension);
			}
		});
	}

	/**
	 * Perform post-processing logic on the model after it has been created and is ready
	 */
	private static postprocessModel(model: VueModel, enumValues: EnumValuesLookup): void {
		// Preprocess enum types
		Object.keys(enumValues).forEach(enumTypeName => {
			const enumJsType = model.getJsType(enumTypeName);
			if (enumJsType && enumJsType['meta']) {
				const enumType = enumJsType['meta'];
				// Store the enum lookup on the type
				enumType.enum = enumValues[enumTypeName];
				// Override the enum's get function to ensure that enums are created
				const originalGet = enumType.get;
				enumType.get = getEnumInstance.bind(null, enumType, originalGet);
			}
		});
	}

	/**
	 * Creates a new forms model using the given entry type and model options
	 * @param entryTypeName The full type name of the root entry type
	 * @param options The model options object
	 */
	static async createModel(entryTypeName: string, options: FormsModelOptions): Promise<FormsModel> {
		// Ensure VueModel is installed
		Vue.use(VueModel);

		options.$locale = options.$locale || 'en';
		const locale = options.$locale;
		const cultureInfo = options.$culture as CultureInfo;
		let localeResources: { [name: string]: string } = options.$locale as any;

		// Load resources
		if (typeof options.$locale !== 'object')
			localeResources = (await import(/* webpackChunkName: '[request]' */ `../localization/resources/${locale}`)).default;

		if (Object.keys(options).includes('Address')) {
			const countriesModule = (await import(/* webpackChunkName: '[request]' */ `../localization/countries/${locale}.json`)).default;
			countries.registerLocale(countriesModule);
		}

		// Globally define resources for the model's locale
		if (locale && localeResources && !FormsModel.definedLocales.has(locale)) {
			VueModel.defineResources(locale, localeResources);
			FormsModel.definedLocales.add(locale);
		}

		return new FormsModel(entryTypeName, options, locale, localeResources, cultureInfo);
	}

	/**
	 * Creates an instance of the model's root form entry type
	 * @param state The property values to initialize the object with
	 */
	async constructEntry<T extends FormEntry>(state: any = {}, reset: boolean = false): Promise<EntityOfType<T>> {
		if (reset) {
			const id = getIdFromState(this.resolveType<FormEntry>(this.entryTypeName), state);
			this.resetEntry(this.entryTypeName, id);
		}

		const entry = await this.constructAsync<T>(this.entryTypeName, state, reset);

		if (!this.modelOptions.$disableWorkflowActions)
			(entry as EntityOfType<T> & FormEntryRuleMethods).initDefaultAction();

		return entry;
	}

	constructEntryMeta(state: EntityArgsOfType<EntryMeta> = {}): Promise<EntityOfType<EntryMeta>> {
		const entryType = this.resolveType<FormEntry>(this.entryTypeName);
		const entryMetaType = entryType.getProperty('Entry').propertyType as EntityConstructorForType<EntryMeta>;
		return entryMetaType.meta.create(state);
	}

	resetEntry<T extends FormEntry>(type: string, id: string) {
		// Get the model type with the given name
		const modelType = this.resolveType<T>(type);
		if (!modelType)
			throw new Error(`Could not construct instance of type '${type}'. Type not found.`);

		if (id) {
			const instance = modelType.get(id);
			if (instance) {
				unregisterEntity(instance);
				if ((instance as any).Order)
					unregisterEntity((instance as any).Order);
			}
		}
	}

	/**
	 * Constructs a new entity of the given type asynchronously
	 * Can be used to create entities with lookup references.
	 * @param type The type of entity to construct
	 * @param state The optional state to assign to the entity
	 */
	async constructAsync<T>(type: string, state: EntityArgsOfType<T> = {}, reset: boolean = false): Promise<EntityOfType<T>> {
		// Get the model type with the given name
		const modelType = this.resolveType<T>(type);

		if (!modelType)
			throw new Error(`Could not construct instance of type '${type}'. Type not found.`);

		const id = getIdFromState(modelType, state);
		if (id) {
			const instance = modelType.get(id) as EntityOfType<T>;
			if (instance) {
				if (reset) {
					unregisterEntity(instance);
				}
				else {
					await instance.update(state);
					return Promise.resolve(instance);
				}
			}
		}
		return modelType.create(state) as Promise<EntityOfType<T>>;
	}

	resolveType<T>(type: string) {
		return this.model.types[type] as TypeOfType<T>;
	}

	/**
	 * Constructs a new entity of the given type
	 * Should only be used if you know the entity in question has no lookup references.
	 * @param type The type of entity to construct
	 * @param state The optional state to assign to the entity
	 * @deprecated This method should only be used if it is not possible to use `constructAsync`
	 */
	construct<T>(type: string, state: EntityArgsOfType<T> = {}, reset: boolean = false): EntityOfType<T> {
		// Get the model type with the given name
		const modelType = this.resolveType<T>(type);
		if (!modelType)
			throw new Error(`Could not construct instance of type '${type}'. Type not found.`);

		const id = getIdFromState(modelType, state);
		if (id) {
			const instance = modelType.get(id) as EntityOfType<T>;
			if (instance) {
				if (reset) {
					unregisterEntity(instance);
				}
				else {
					instance.update(state);
					return instance;
				}
			}
		}

		// Call the type's `createSync()` factory method
		return modelType.createSync(state) as EntityOfType<T>;
	}

	initialValueResolver(instance: Entity, property: Property, value: any): Promise<any> | void {
		// Resolve lookup data
		if (this.lookupValueResolver)
			return this.lookupValueResolver(instance, property, value);
	}

	format(value: number | Date, format: string) {
		return typeof (value) === 'number'
			? this.model.formatNumber(value, format)
			: this.model.formatDate(value, format);
	}

	parse(type: NumberConstructor | DateConstructor, value: string, format?: string): number | Date {
		if (type === Number) {
			return this.model.parseNumber(value, format);
		}
		else {
			return this.model.parseDate(value, format ? [format] : null);
		}
	}

	expandDateFormat(format: string): string {
		return this.model.expandDateFormat(format);
	}

	get version() {
		return this.modelOptions.$version;
	}

	enableLookups(entryViewService: EntryViewService, getLookupManager: LookupManagerFactory, getCascadeFilterManager: CascadeFilterManagerFactory, pseudoLoadDeleted = false) {
		if (!this.lookupValueResolver)
			this.lookupValueResolver = extendModelWithLookups(this, this.modelOptions, entryViewService, getLookupManager, getCascadeFilterManager, pseudoLoadDeleted);
	}

	overrideResource(resourceName: string, value: string) {
		this.model.$resources[this.model.$locale][resourceName] = value;
	}
}

/**
 * Provide optional modules available to the form's model definition
 * @param name The name of the module to import
 * @param pendingModules The list of modules that are currently pending
 */
export async function importOptionalModule(name: string, pendingModules: Promise<any>[] = null): Promise<any> {
	// Async load the module
	let modulePromise: Promise<any>;
	switch (name) {
		case 'time-span':
			modulePromise = import(/* webpackChunkName: "time-span" */ './model/time-span');
			break;
		case 'order-builder':
			modulePromise = import(/* webpackChunkName: "order-builder" */ './payment/order-builder');
			break;
		default:
			throw new Error('Cannot dynamically load module \'' + name + '\'.');
	}

	if (pendingModules) {
		// Store the requested module promise so that we can detect when it has loaded
		pendingModules.push(modulePromise);
	}

	// Return the module promise to the caller
	return modulePromise;
}
