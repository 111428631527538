import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter } from '@cognitoforms/model.js';

export default class DateConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.propertyType === Date && prop.format.specifier === 'd';
	}

	serialize(context: Entity, value: any, prop: Property): PropertySerializationResult {
		return {
			key: prop.name,
			value: value ? ((value.getFullYear())
				+ (value.getMonth() < 9 ? '-0' : '-') + (value.getMonth() + 1)
				+ (value.getDate() < 10 ? '-0' : '-') + value.getDate())
				: null
		};
	}

	deserialize(context: Entity, value: any, prop: Property) {
		if (typeof value === 'string')
			return this.parseDate(value);
		return value;
	}

	private parseDate(date: string) {
		const parts = date.split('-').map(Number);
		return new Date(parts[0], parts[1] - 1, parts[2]);
	}
}