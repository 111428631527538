import type { EntityConstructor, EntityOfType, Type, TypeOfType } from '@cognitoforms/model.js';
import { isEntityType } from '@cognitoforms/model.js';
import type { FormsModel } from 'src/framework/forms-model';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { WorkflowAction } from '@cognitoforms/types/server-types/forms/model/workflow-action';
import type { FormEntryWorkflowExtensions } from 'src/framework/model/extensions/form-entry-extensions';

export interface WorkflowActionExtensions {
	IsAllowed: boolean;
	Form: FormEntry;
}

function addWorkflowActionsProperty(entryType: Type, actionDataMap: { [type: string]: WorkflowAction; }, formsModel: FormsModel) {
	entryType.extend({
		Action_IsLocked: Boolean,
		Attempted_Action: String,
		Workflow_Actions: {
			type: 'Forms.WorkflowAction[]',
			get(this: EntityOfType<FormEntry>) {
				return Object.keys(actionDataMap)
					.map(actionType => {
						const actionData = actionDataMap[actionType];
						if (actionData)
							return formsModel.construct<WorkflowAction & WorkflowActionExtensions>(actionType, { ...actionData, Form: this });
						else
							return null;
					})
					.filter(a => a !== null);
			}
		},
		Allowed_Actions: {
			type: 'Forms.WorkflowAction[]',
			get: {
				function(this: EntityOfType<FormEntry & FormEntryWorkflowExtensions>) {
					return this.Workflow_Actions.filter(a => a.IsAllowed && !a.IsArchived);
				},
				dependsOn: 'Workflow_Actions{IsAllowed,IsArchived}'
			}
		}
	});

	const entryMetaType = entryType.getProperty('Entry').propertyType as EntityConstructor;
	if (isEntityType(entryMetaType) && entryMetaType.meta.getProperty('Action')) {
		entryType.extend({
			initDefaultAction(this: EntityOfType<FormEntry & FormEntryWorkflowExtensions>) {
				// Use setTimeout to ensure that setting the default action occurs after all other initialization
				// events and rules have occurred. Setting the default action is the first "change" to the entry and
				// may rely on some other initialization logic (ex: lookup initialization).
				const firstAllowedAction = this['Allowed_Actions'][0];
				if (firstAllowedAction)
					this.Entry.Action = firstAllowedAction.ActionName;
			}
		});

		entryType.addRule({
			name: 'UpdateDefaultAction',
			execute(this: EntityOfType<FormEntry & FormEntryWorkflowExtensions>) {
				const firstAllowedAction = this['Allowed_Actions'][0];
				if (!this.Action_IsLocked && !this.Form_ReadOnly) {
					if (this.Attempted_Action && !this.Allowed_Actions.find(a => a.ActionName === this.Attempted_Action))
						this.Attempted_Action = null;

					if (!this.Attempted_Action) {
						if (firstAllowedAction)
							this.Entry.Action = firstAllowedAction.ActionName;
						else
							this.Entry.Action = null;
					}
				}
			},
			// this rule intentionally only runs in response to allowed actions changing
			onChangeOf: entryType.getPaths('{Allowed_Actions}')
		}).register();
	}
}

function getActiveEntryStatus(entry: EntityOfType<FormEntry & FormEntryWorkflowExtensions>, statusIdOrName) {
	const newStatus = entry.Entry_Statuses.find(s => s.Id === Number(statusIdOrName) || s.Name === statusIdOrName);
	if (newStatus == null || newStatus.ReplacementId == null)
		return newStatus;
	else
		return getActiveEntryStatus(entry, newStatus.ReplacementId);
}

function addWorkflowNextStatusProperty(entryType: Type) {
	entryType.extend({
		Next_Status: {
			type: String,
			get: {
				function(this: EntityOfType<FormEntry & FormEntryWorkflowExtensions>) {
					const entryMeta = this.Entry;
					const action = this.Workflow_Actions.find(a => a.ActionName === entryMeta.Action) as WorkflowAction;
					let nextStatus = entryMeta.Status;

					if (typeof nextStatus !== 'string')
						nextStatus = getActiveEntryStatus(this, nextStatus).Name;

					if (action && action.NewStatus !== null)
						nextStatus = getActiveEntryStatus(this, action.NewStatus).Name || nextStatus;

					return nextStatus;
				},
				dependsOn: '{Entry{Action,Status}, Workflow_Actions{NewStatus}, Entry_Statuses}'
			}
		}
	});
}

export function applyWorkflowActionExtensions(formsModel: FormsModel, entryType: TypeOfType<FormEntry>, actionDataMap: { [type: string]: WorkflowAction }) {
	if (formsModel.resolveType<WorkflowAction>('Forms.WorkflowAction')) {
		addWorkflowActionsProperty(entryType, actionDataMap, formsModel);

		if (entryType.getProperty('Entry_Statuses')) {
			addWorkflowNextStatusProperty(entryType);
		}
	}
}
