import { AdminFormHandle } from './admin-form-handle';
import type Vue from 'vue';
import type { FormOwner } from '../public/form-handle';
import ConcurrentEntryService from 'src/web-api/concurrent-entry-service';
import type { EntryData } from 'src/web-api/entry-views/entry-view-types';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import { closeAllToastMessages } from 'src/components/ToastMessage';
import type { Entity, EntityChangeEventArgs } from '@cognitoforms/model.js';
import type { EventHandler } from '@cognitoforms/model.js/@types/events';
import { FormEvent } from '../eventing/form-event';
import type { AfterSubmitEvent, AfterSubmitEventData } from '../public/events';
import { FormEvents } from '../public/events';
import { EntryStatus } from 'src/components/EntryStatus';
import { FormStatus } from 'src/mixins/form-status';
import { ViewType } from '@cognitoforms/types/server-types/forms/model/view-type';

export class FormViewFormHandle extends AdminFormHandle {
	private changeHandler: EventHandler<Entity, EntityChangeEventArgs>;
	private publicRoleUnavailable: boolean;
	private afterSubmitEventData: AfterSubmitEventData;
	private isPublic: boolean;
	private showPageBreaks: boolean;
	private roleName: string;

	private get formViewComponent() {
		const componentEl = document.querySelector('.entries-form-view');
		if (componentEl)
			return (componentEl as unknown as { __vue__: Vue & Record<string, unknown> }).__vue__;
	}

	constructor(formId: string, owner: FormOwner, ready: Promise<any>) {
		super(formId, owner, ready);

		this.formAvailable.then(() => {
			this.on(FormEvents.AfterSubmit, (event: AfterSubmitEvent) => this.afterSubmit(event));
			this.on(FormEvents.BeforeSubmit, () => this.beforeSubmit());
			this.on(FormEvents.AfterSave, () => this.setNoChanges());
			this.once(FormEvents.Ready, () => this.setNoChanges());
		});
	}

	destroy() {
		this.disableChangeDetection();
		super.destroy();
	}

	async isFormAvailable() {
		await this.formAvailable;
		return this.form.available;
	}

	hasChangeHandler() {
		return this.changeHandler !== null && this.changeHandler !== undefined;
	}

	async changeView(viewId: string, token: string): Promise<void> {
		await this.formAvailable;
		if (!this.form || !this.form.entryViewService) {
			return;
		}

		await this.disableChangeDetection();

		this.form.entryViewService.registerViewToken(viewId, token);
		if (this.form.entryService instanceof ConcurrentEntryService) {
			this.form.entryService.registerView(viewId, ViewType.Form);
		}

		this.setNoChanges();
	}

	private beforeSubmit() {
		// The event handler calls setRedirectingToView based on the view potentially being redirected to
		this.formViewComponent.$emit('before-submit');
	}

	public setIsPublic(isPublic: boolean) {
		this.isPublic = isPublic;
	}

	public getIsPublic() {
		return this.isPublic;
	}

	public setRoleName(roleName: string) {
		this.roleName = roleName;
	}

	public getRoleName() {
		return this.roleName;
	}

	public setShowPageBreaks(showPageBreaks: boolean) {
		this.showPageBreaks = showPageBreaks;
	}

	public getShowPageBreaks() {
		return this.showPageBreaks;
	}

	public setRedirectingToView(redirecting: boolean) {
		this.form.redirectingToEntryView = redirecting;
	}

	private async afterSubmit(event: AfterSubmitEvent) {
		// We don't want to show save changes dialog if the entry is submitted
		await this.disableChangeDetection();
		this.setNoChanges();

		this.afterSubmitEventData = event.data;
		this.formViewComponent.$emit('form-view-submitted');
	}

	public showConfirmationMessage() {
		const actionName = this.afterSubmitEventData.entry.Entry.Action;
		const action = this.form.actions.find(a => a.ActionName === actionName);
		const confirmationMessage = action.Confirmation.Message;
		const redirectUrl = action.Confirmation.RedirectUrl;

		const formEntry = this.form.entry;
		const convertedMessage = formEntry.toString(confirmationMessage);

		if (confirmationMessage && !redirectUrl)
			this.formViewComponent.showConfirmationMessage(convertedMessage);
	}

	public showConfirmationPage() {
		this.form.showConfirmationPage(this.afterSubmitEventData.documents);
	}

	private setNoChanges() {
		if (this.formViewComponent) {
			this.formViewComponent.$emit('set-no-changes');
		}
	}

	async addChangesDetected(handler: EventHandler<Entity, EntityChangeEventArgs>) {
		await this.disableChangeDetection();
		this.changeHandler = handler;
		await this.enableChangeDetection();
	}

	private async enableChangeDetection() {
		await this.formAvailable;
		if (this.changeHandler) {
			this.form.formsModel.model.afterPropertySet.subscribe(this.changeHandler);
			this.form.formsModel.model.listChanged.subscribe(this.changeHandler);
		}
	}

	private async disableChangeDetection() {
		await this.formAvailable;

		// Clear the list of subscriptions
		this.form.formsModel.model.afterPropertySet.clear();
		this.form.formsModel.model.listChanged.clear();
		this.setNoChanges();
	}

	activityPerformed() {
		this.emit(new FormEvent(FormEvents.ActivityPerformed));
	}

	async setCorrectFormAvailability(isPublic: boolean) {
		await this.formAvailable;
		if (!isPublic && !this.form.available) {
			this.publicRoleUnavailable = true;
			this.form.available = true;
		}
		// If we were previously in a non-public form view and switch to the
		// public role, respect availability settings
		else if (isPublic && this.form.available && this.publicRoleUnavailable) {
			this.form.available = false;
		}
	}

	async setNewEntry(roleName: string, isPublic: boolean): Promise<void> {
		// Wait for the form to be available and disable change detection
		await this.disableChangeDetection();
		const entryJson = {
			Entry: {
				Role: roleName,
				User: this.form.session.userInfo,
				Status: 'Incomplete'
			}
		} as EntryData;

		this.form.entryStatus = EntryStatus.Pending;
		this.form.startingPage = 1;
		const entryInstance = await this.form.formsModel.constructEntry(entryJson, true);

		this.form.entry.Entry.LastPageViewed = null;
		this.form.entry.Entry.Role = roleName;

		await this.setCorrectFormAvailability(isPublic);

		await this.reset(entryInstance, entryJson);

		// Re-enable change detection
		await this.enableChangeDetection();

		await this.form.$nextTick();

		this.form.entryStatus = EntryStatus.Ready;

		this.activityPerformed();
	}

	private reset(entry: FormEntry, initialEntryJson: Record<string, any>) {
		if (this.form.quantityService) {
			const hasInitialState = !entry.meta.isNew && entry.Entry.Status !== 'Incomplete';
			this.form.quantityService.reset({ hasInitialState: hasInitialState, root: entry });
		}

		if (this.form.entryService instanceof ConcurrentEntryService)
			this.form.entryService.updateInitialEntryJson(initialEntryJson);

		// Set the current action to the first allowed action, if any
		if (this.form.allowedActions.length)
			this.form.entry.Entry.Action = this.form.allowedActions[0].ActionName;
		else
			this.form.entry.Entry.Action = null;

		closeAllToastMessages();

		return new Promise(resolve => {
			this.form.withTransitionsDisabled(async (form) => {
				form.log.registerEntry(entry);
				form.showConfirmation = false;
				form.showReceipt = false;
				form.submitStatus = FormStatus.Default;
				form.pageNumber = this.form.startingPage;
				form.readonly = form.$source.readonly = form.entry.Form_ReadOnly;
				if (form.quantityService)
					await form.quantityService.refresh();
				form.entry = entry;
				await this.emit(new FormEvent(FormEvents.ResetEntry));
				resolve(entry);
			});
		});
	}
}