import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter, IgnoreProperty, isEntityType } from '@cognitoforms/model.js';
import { ORDER_TYPE_NAME } from 'src/framework/forms-model';

/**
 * Prevents the order off the entry from being serialized or deserialized.
 */
export default class OrderConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		return prop.name === 'Order' && isEntityType(prop.propertyType) && prop.propertyType.meta.fullName === ORDER_TYPE_NAME;
	}

	serialize(): PropertySerializationResult {
		return IgnoreProperty;
	}

	deserialize(context: Entity, value: any, property: Property): any {
		return super.deserialize(context, value, property);
	}
}