export enum FormStatus {
	Default = 'default',
	Success = 'success',
	InProgress = 'validating',
	Error = 'error',
	Captcha = 'captcha',
	Authenticating = 'authenticating'
}

export const FormStatusProp = {
	type: String,
	default: FormStatus.Default,
	validator: value => {
		return Object.values(FormStatus).includes(value);
	}
};