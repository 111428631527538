import type { EntityOfType } from '@cognitoforms/model.js';
import { hasValue } from '../../../util/helpers';
import type { Signature } from '@cognitoforms/types/server-types/model/signature';

export interface SignatureMethods {
	validateRequiredSignature(this: EntityOfType<Signature>): string;
}

export const SignatureExtensions: SignatureMethods = {
	validateRequiredSignature(this: EntityOfType<Signature>) {
		// Store the model to use for localization when building the error message
		const model = this.meta.type.model;

		let error = '';

		if (!hasValue(this.Svg)) {
			error = model.getResource('required');
		}

		return error;
	}
};
