/**
 * Rounds the given number to a particular number of digits
 * NOTE: Ported from 'Cognito.Forms.roundToEven'
 * @param num The number to rund
 * @param digits The number of digits to round to
 */
export function roundToPrecision(num: number, digits: number) : number {
	digits = digits || 0;
	const multiplier = Math.pow(10, digits);
	const nFixed = + (digits ? num * multiplier : num).toFixed(8);
	const nInt = Math.floor(nFixed);
	const nDecimals = nFixed - nInt;
	const e = 1e-8;
	const remainder = (nDecimals > 0.5 - e && nDecimals < 0.5 + e) ? ((nInt % 2 === 0) ? nInt : nInt + 1) : Math.round(nFixed);

	return digits ? remainder / multiplier : remainder;
}
