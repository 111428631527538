import type { Property, PropertySerializationResult, Entity } from '@cognitoforms/model.js';
import { PropertyConverter, IgnoreProperty } from '@cognitoforms/model.js';
import { FORM_ENTRY_TYPE_NAME } from 'src/framework/forms-model';

/**
 * Excludes certain properties from serialization because they represent circular references.
 */
export default class IgnoreCircularReferenceConverter extends PropertyConverter {
	shouldConvert(context: Entity, prop: Property): boolean {
		if (prop.name === 'ParentSection')
			return true;
		// Convert any 'Form' property that is not the FormEntry's FormRef property
		if (prop.name === 'Form' && prop.containingType.fullName !== FORM_ENTRY_TYPE_NAME)
			return true;
	}

	serialize(): PropertySerializationResult {
		return IgnoreProperty;
	}
}